import React, {useContext} from 'react';
import {Modal, Button, Form, Image} from 'react-bootstrap';
import InternsLogo from '../../../assets/logo_interns.png';
import Methods from '../../../utils/https';
import { notify } from '../../../utils/notify';
import {UserContext} from '../../../context/UserContext';
import moment from 'moment';
const axios = require("axios");


export default function ApprovePrefacture({
    totalDual,
    countInterns,
    approvedInterns,
    period,
    showApprovePrefacture,
    setShowApprovePrefacture,
    updateList,
    setUpdateList,
    prefactureData,
    payDay,
    payDayLimit,
    isSending,
    setIsSending,
    previusQMPrefacture,
    previusQMPrefactureDetails,
    prefacturePaymentsAux,
    consolidationValidations
}) {
    const context = useContext(UserContext);
    const company = context.user.company;
    const userFullName = `${context.user.data.fullName}`;
    const userEmail = context.user.data.email;
    const role = context.user.data._role;
    const [startDate, endDate] = period;
    const formattedStartDate = moment(startDate).format("D/MM/YYYY");
    const formattedEndDate = moment(endDate).format("D/MM/YYYY");

    const approvePrefacture = async (e) => {
        e.preventDefault();
        let approvedInternsAux = approvedInterns;
        let dateLimit = moment(getFormatedDate(payDayLimit));
        let currentDate = moment();
        let isAdmin = context.user.data.isCaintraUser;
        let promises = [];
        console.log(currentDate.diff(dateLimit, 'days'))
        console.log(dateLimit)
        if(currentDate.diff(dateLimit, 'days') > 0 && !isAdmin){
            notify("Haz sobrepasado la fecha límite de autorización, para seguir con el proceso de esta prefactura pide ayuda a tu Asesor CAINTRA o su usuario no está habilitado para realizar la acción solicitada", "error");
            return;
        }

        if(!context.can("approve", "Prefacture")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return;
        };

        if(secondPeriodNoInternsValidations(approvedInterns, isAdmin, previusQMPrefacture, consolidationValidations)) return;

        const internsIds = approvedInterns.map(intern => intern._id);
        const confirmation = e.target.elements.confirmation.value;

        let errors = await verifyErrors(
            previusQMPrefacture, 
            isAdmin, 
            currentDate, 
            dateLimit, 
            context, 
            confirmation,
            approvedInterns
        );
        if(errors > 0) return;
        
        let startDateInit = moment(startDate).format("YYYY-MM-DD") + "T00:00:00.000Z"
        let endDateInit = moment(endDate).format("YYYY-MM-DD") + "T00:00:00.000Z"



        const data = {
            interns: internsIds,
            period: [startDateInit, endDateInit],
            companyId: company._id,
            rolledBack: false,
            authorEmail: userEmail, 
            authorName: userFullName,
            createdAt: moment().format(),
            periodType: getFormatedPayment(company.payment_period),
            reportType: 'Authorization',
            companyName: context.user.company.business_name,
            periodDates:  `PERIODO DEL ${moment(startDateInit.substring(0,10)).format('D/MM/YYYY')} AL ${moment(endDateInit.substring(0,10)).format('D/MM/YYYY')}`,
            authorizeAuthorEmail: userEmail,
            authorizeAuthorName: userFullName,
            authorizeDate: moment().format()
        };

        const record = {
            message: `Aprobación de prefactura del periodo ${formattedStartDate} al ${formattedEndDate}`,
            author: userFullName,
            role: role,
            companyId: company._id,
            createdAt: new Date(),
            authorEmail: userEmail,
        };
        setIsSending(true);

        await setMonthlyInsurancePayments(prefacturePaymentsAux, approvedInternsAux, promises);

        await setRetroactivePayments(prefacturePaymentsAux.retroactivePayments, promises);

        if(promises.length){
            await Promise.all(promises);
        }    
        console.log("APROBANDO PREFACTURA",totalDual,
        countInterns,)
        await Methods.createPrefacture(
            totalDual,
            countInterns,
            data,
            company.business_name, 
            prefactureData, 
            userFullName, 
            formattedStartDate,
            formattedEndDate,
            approvedInternsAux, 
            payDay, 
            new Date(),
            consolidationValidations,
            isForcedValidation() ? undefined : previusQMPrefacture,
            isForcedValidation() ? undefined : previusQMPrefactureDetails,
            company._id
        );
        await Methods.createRecord(record);

        createNotification(internsIds.length);
        setUpdateList(!updateList);
        setShowApprovePrefacture(!showApprovePrefacture);
        setIsSending(false);
    };

    const secondPeriodNoInternsValidations = (
        approvedInterns, 
        isAdmin, 
        previusQMPrefacture, 
        consolidationValidations
    ) => {

        if(consolidationValidations.isSecondConsolidationPeriod || consolidationValidations.isSecondQMPeriod){  
            if(!approvedInterns.length && !isAdmin){
                notify("No puedes aprobar un periodo sin practicantes, solicítalo a un administrador", "error");
                return true;
            }

            if(!previusQMPrefacture?.length && !approvedInterns.length){
                notify("Necesitas al menos un periodo con practicantes", "error");
                return true;
            }
        }

        return false;
    }

    const isForcedValidation = () => {   
        let periodFormated = getFormatedPayment(company.payment_period)
        return (consolidationValidations?.forceApprovePeriod || false ) && consolidationValidations?.isSecondConsolidationPeriod  && (periodFormated === 'Quincenal-Mensual C')
    } 

    const getFormatedPayment = (payment_period) =>{
        const formatedPayment = {
            "CATORCENAL": 'Catorcenal',
            "QUINCENAL": 'Quincenal',
            "QUINCENAL MENSUAL":'Quincenal-Mensual',
            "MENSUAL 15": 'Mensual 15',
            "MENSUAL 30": 'Mensual 30',
            "MENSUAL 15 BAT": 'Mensual 15 BAT'
        }
        return formatedPayment[payment_period] || payment_period;
    }

    const setMonthlyInsurancePayments = async (prefacturePaymentsAux, approvedInternsAux, promises) =>{

        if(prefacturePaymentsAux.insuranceTotal.length){
            for(let insurance of prefacturePaymentsAux.insuranceTotal){
                for(let approvedIntern of approvedInternsAux){
                    if(insurance.idIntern == approvedIntern._id){
                        approvedIntern.isTotalPayment = true;
                        if(!approvedIntern.idInsurancesToPay){
                           approvedIntern.idInsurancesToPay = [];
                        }
                        approvedIntern.idInsurancesToPay.push(insurance.insuranceId);
                    }
                }

                let dataIntern = {
                    _id: insurance.insuranceId,
                    attributes: []
                }

                dataIntern.attributes.push({updateKey: "isPaid", updateValue: true});
                promises.push(Methods.updateInternInsurance(dataIntern));
                await cleanPromises(promises);
            }
        }
    }

    const setRetroactivePayments = async(retroactivePayments, promises) => {
        if(retroactivePayments.length){
            for(let retroactive of retroactivePayments){

                    let dataIntern = {
                        _id: retroactive.contractId,
                        attributes: [
                            {updateKey: "isRetroactivePayment", updateValue: false},
                            {updateKey: "retroactivePaymentDate", updateValue: retroactive.payDay}
                        ]
                    }
                    
                    promises.push(Methods.updateContractById(dataIntern));
                    await cleanPromises(promises);

            }
        }
    }

    const cleanPromises = async (promises) => {
        if(promises.length == 300){
           await Promise.all(promises);
           promises = [];
        }
    }


    const verifyErrors = (previusQMPrefacture, isAdmin, currentDate, dateLimit, context, confirmation, approvedInterns) => {
        if(!previusQMPrefacture && (consolidationValidations.isSecondQMPeriod || consolidationValidations.isSecondConsolidationPeriod) 
            && consolidationValidations.requiereFirstPeriodApproved && role != 'Administrador Caintra'){
            //notify("Aún no has autorizado el primer periodo del mes", "error");
            //return 1;
        }

        if(currentDate.diff(dateLimit, 'days') > 0 && !isAdmin){
            notify("Haz sobrepasado la fecha límite de autorización, para seguir con el proceso de esta prefactura pide ayuda a tu Asesor CAINTRA..", "error");
            return 1;
        }

        if(!context.can("approve", "Prefacture")){
            notify("No tienes acceso a esta funcionalidad, solicítalo a un administrador", "error");
            return 1;
        };

        if(confirmation.trim().toLowerCase() != "autorizar"){
            notify("La palabra de confirmación es incorrecta, por favor intenta nuevamente", "error");
            return 1;
        };

        if(!approvedInterns.length && !consolidationValidations.isSecondConsolidationPeriod && !consolidationValidations.isSecondQMPeriod){
            notify("No puedes autorizar un periodo sin practicantes", "error");
            return 1;
        }
    }


    const getFormatedDate = (dateString) => {
        const regexDate = new RegExp(/^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/);
        let result;
        let year;
        let month;
        let day;
        if(regexDate.test(dateString)){
            const newDateArray = dateString.split("-");
            year = newDateArray[0];
            month = addZeroToDate(newDateArray[1]);
            day = addZeroToDate(newDateArray[2]);
            result = month + "/" + day + "/"+ year;
        } else {        
            const newDateArray = dateString.split("/");
            month = addZeroToDate(newDateArray[0]);
            day = addZeroToDate(newDateArray[1]);
            result = day + "/" + month + "/" + newDateArray[2];
        }
        return result;
    }

    const addZeroToDate =  (value) => {
        if(value.length == 1){
            return "0" + value;
        } else{
            return value;
        }
    };

    const createNotification = async (interns) => {
        const URL = process.env.REACT_APP_SOCKET_URL;
        let description = ""
        if(interns == 1){
            description = "Un practicante ha sido autorizado en pre-factura"
        } else {
            description = `${interns} practicantes han sido autorizados en pre-factura`
        }

        const notification = {
            name: "Autorización de prefactura",
            user: "Administrador Caintra",
            description: description,
            type: " ",
            date: new Date()
        };

        try{
            await axios({
                url: `${URL}/notifications`,
                method: "POST",
                data: notification
            });
        } catch (error) {
            
        };
    };

    return(
        <Modal className="prefacture-modals" show={showApprovePrefacture} onHide={() => setShowApprovePrefacture(!showApprovePrefacture)} centered>
            <Modal.Body>
                <Form onSubmit={approvePrefacture} autoComplete={"off"}>
                    <p>Estás a punto de aprobar a {approvedInterns ? approvedInterns.length : 0} practicante(s) del periodo {formattedStartDate} al {formattedEndDate}</p>
                    <small>¡Recuerda revisar todos los datos y documentos antes de continuar!</small>

                    <Form.Group style={{marginTop: "20px"}} controlId="confirmation">
                        <Form.Control type="text" placeholder='Escribe "autorizar" aquí' required/>
                    </Form.Group>

                    <Button className="float-right ml-2" disabled={isSending} variant="primary" type="submit">
                        Confirmar
                    </Button> 

                    <Button className="float-right" variant="danger" onClick={() => {setShowApprovePrefacture(!showApprovePrefacture);  setIsSending(false);}}>
                        Cancelar
                    </Button>             
                </Form>
            </Modal.Body>
        </Modal>
    );
};