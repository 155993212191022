import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faExclamationTriangle,
  faCamera,
  faBan,
  faEye,
  faCheckCircle,
  faTimesCircle,
  faFileDownload,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { notify } from "../../../utils/notify";
import { DateTimePicker } from "react-widgets";
import { motion } from "framer-motion";
import InternMedicalInsurance from "./InternMedicalInsurance.jsx";
import VerifyChangeTab from "./VerifyChangeTab.jsx";
import VerifyChangedOnDate from "./VerifyChangedOnDate.jsx";

import NewContractTab from "./NewContractTab.jsx";
import RenewIntern from "./RenewIntern.jsx";
import UpdateContractStatus from "./UpdateContractStatus.jsx";
import DefaultUser from "../../../assets/default-user.png";
import Methods from "../../../utils/https";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import TemplatePDF from "./TemplatePDF.jsx";
import { UserContext } from "../../../context/UserContext";
import { TimePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import LoadingModal from "../../../custom/LoadingModal/LoadingModal";
import { ProgressBar } from "../../../custom/ProgressBar/ProgressBar.jsx";
import plusIconSvg from "../../../assets/IconsCaintra/plusIcon.svg";
import NewDepartmentModal from "./NewDepartmentModal.jsx";
import NewCostCenterModal from "./NewCostCenterModal.jsx";
import CreateLeaderUserModal from "./CreateLeaderUserModal.jsx";
import InternDocumentsTable from "./InternDocumentsTable.jsx";
import {
  deleteInsuranceIdAndPolicies,
  getInsuranceDataToSend,
} from "../../../utils/insuranceMethods";
import { sendITESMdocs } from "../../../utils/signatureMethods";
import ApproveConvenio from "./ApproveConvenio";
import { decrypt } from "../../../utils/crypto";

const axios = require("axios");
const uuid = require("uuid");
const $ENV = "dev"; //modified in buildspec

const sifeEmailUrl = () => {
  const sife = {
    dev: "https://dev.sife.io/",
    prod: "https://sife.io/",
  };

  return sife[$ENV || "dev"] || sife.dev;
};

export default function VerifyInternDocs({
  currentInternId,
  interns,
  setCurrentTab,
  setUpdateList,
  updateList,
  updatedFromPrefacture,
  setContractToUpdateRenew,
  setContractToUpdateZipCode,
}) {
  const context = useContext(UserContext);
  const canEdit = context.can("update", "Interns");
  const URL = process.env.REACT_APP_SOCKET_URL;
  const insurance_payment = context.user.company.insurance_payment;
  const currentCompanyId = context.user.company._id;
  const [currentInternData, setCurrentInternData] = useState({});
  const [internDocs, setInternDocs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [currentDepartmentId, setCurrentDepartmentId] = useState("");
  const [internDataAux, setInternDataAux] = useState({
    contractEndDate: undefined,
    contractStartDate: undefined,
    birthDate: undefined,
    gender: "",
    internAge: 0,
    rfc: "",
    requireInsurance: false,
    requireRegion: false,
    scholarshipAmount: 0,
    currentCollege: "",
  });
  const {
    contractEndDate,
    contractStartDate,
    birthDate,
    gender,
    internAge,
    rfc,
    requireInsurance,
    scholarshipAmount,
    currentCollege,
  } = internDataAux;
  const [renewContractEndDate, setRenewContractEndDate] = useState(undefined);
  const [renewContractStartDate, setRenewContractStartDate] =
    useState(undefined);
  const [updateInfo, setUpdateInfo] = useState(false);
  const [documentationTab, setDocumentationTab] = useState("internDocs");
  const [universitySelected, setUniversitySelected] = useState("");
  const [facultySelected, setFacultySelected] = useState("");
  const [universities, setUniversities] = useState([]);
  const [currentState, setCurrentState] = useState("");
  const [currentSuburbs, setCurrentSuburbs] = useState([]);
  const [currentMunicipality, setCurrentMunicipality] = useState("");
  const [showApproveConvenio, setShowApproveConvenio] = useState(false);
  const [showVerifyChangeTab, setShowVerifyChangeTab] = useState(false);
  const [showVerifyChangeOnDate, setShowVerifyChangeOnDate] = useState(false);
  const [reloadProgressBar, setReloadProgressBar] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [leaderInfo, setLeaderInfo] = useState({
    currentLeaderEmail: "",
    currentLeaderName: "",
  });
  const { currentLeaderEmail, currentLeaderName } = leaderInfo;
  const [newTabName, setNewTabName] = useState("");
  const [entryTime, setCurrentEntryTime] = useState(undefined);
  const [depurateTime, setCurrentDeputateTime] = useState(undefined);
  const regexEmail = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/
  );
  const [formValidate, setFormValidate] = useState({
    phone: "",
    email: "",
    email_leader: "",
    account: "",
    label_error_email: "El correo no es válido",
    label_error_phone: "El número de teléfono debe tener 10 dígitos",
    label_error_account: "El número de cuenta no es válido",
    is_account_error: false,
    is_phone_error: false,
    is_email_error: false,
    is_email_leader_error: false,
  });
  const [isPhoneError, setIsPhoneError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isEmailLeaderError, setIsEmailLeaderError] = useState(false);
  const [isAccountError, setIsAccountError] = useState(false);
  const [isCurpError, setIsCurpError] = useState(false);
  const [curpErrorLabel, setCurpErrorLabel] = useState(false);
  const gradeArray = [
    "1°",
    "2°",
    "3°",
    "4°",
    "5°",
    "6°",
    "7°",
    "8°",
    "9°",
    "10°",
    "11°",
    "12°",
    "Pendiente",
  ];
  const [isMainBank, setIsMainBank] = useState(true);
  const [currentContractId, setCurrentContractId] = useState(undefined);
  const [showRenewIntern, setShowRenewIntern] = useState(false);
  const [duration, setDuration] = useState(0);
  const [internContracts, setInternContracts] = useState([]);
  const [currentInternContract, setCurrentInternContract] = useState([]);
  const [createNewContract, setCreateNewContract] = useState([]);
  const [showCreateNewContract, setShowCreateNewContract] = useState(false);
  const [isNewContract, setIsNewContract] = useState(false);
  const [showUpdateContrat, setShowUpdateContract] = useState(false);
  const [contractToUpdate, setContratToUpdate] = useState(undefined);
  const [contractActionLabel, setContractActionLabel] = useState("");
  const [isNewCurrentContractIntern, setIsNewCurrentContractIntern] =
    useState(false);
  const [newInternContractData, setNewInternContractData] = useState({});
  const [dataToUpdateByIntern, setDataToUpdateByIntern] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [internPolicies, setInternPolicies] = useState([]);
  const [isRenovationCase, setIsRenovationCase] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(undefined);
  const [currentInsuranceData, setCurrentInsuranceData] = useState(undefined);
  const requiredKeysProgressbar = [
    "age",
    "bank",
    "birthday",
    "career",
    "clabe",
    "college",
    "contractDuration",
    "contractEndDate",
    "contractStartDate",
    "cost_center_name",
    "curp",
    "department_name",
    "directBoss_email",
    "directBoss_name",
    "email",
    "ext_number",
    "faculty",
    "father_lastname",
    "firstname",
    "gender",
    "grade",
    "mother_lastname",
    "municipality",
    "period_type",
    "phone",
    "projectName",
    "rfc",
    "scholarship",
    "school_enrollment",
    "scolarshipAmount",
    "street",
    "suburb",
    "zip_code",
    "_state",
  ];
  const [dateChanged, setDateChanged] = useState(false);
  const [daysMoreThan7, setDaysMoreThan7] = useState(false);
  const [dateConfirm, setDateConfirm] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [showNewDepartmentModal, setShowNewDepartmentModal] = useState(false);
  const [showNewCostCenterModal, setShowNewCostCenterModal] = useState(false);
  const [showCreateLeaderModal, setShowCreateUserModal] = useState(false);
  const isCaintraUser = context.user.data.isCaintraUser;
  const canEditDate = !(
    isCaintraUser &&
    (context.user.data._role == "ADMINISTRADOR" ||
      context.user.data._role == "Administrador Caintra")
  );
  const [selectedApproveConvenio, setSelectedApproveConvenio] = useState(null);
  const [costCentersTec, setCostCentersTec] = useState(null);
  const [generalistasTec, setGeneralistasTec] = useState(null);
  const [companyOptionsAux, setCompanyOptionsAux] = useState({
    requireSife: false,
    currentCompanyName: "",
    isITESMCompany: false,
  });
  const [currentClabe, setCurrentClabe] = useState("");
  const [contractUpdateEqual, setContractUpdateEqual] = useState(false);
  const { requireSife, currentCompanyName, isITESMCompany } = companyOptionsAux;

  useEffect(() => {
    getData();
    if (context.user.company.isITESMCompany == true) {
      Methods.getCostCentersTec().then((data) => {
        setCostCentersTec(data);
      });
      Methods.getGeneralistas().then((data) => {
        setGeneralistasTec(data);
      });
    }
  }, [updateInfo]);

  const getData = async () => {
    setIsLoading(true);
    await Promise.all([
      getInternsDocuments(),
      getAllInternPolicies(),
      getDepartments(),
      getInternDocs(),
    ]);
    setUniversities(context.universities);
    setIsLoading(false);
  };

  useEffect(() => {
    setUniversities(context.universities);
  }, [context.isLoadingUniversities]);

  const getCompanyById = async (id) => {
    const [company] = await Methods.getCompanies([id]);
    setCurrentCompany(company);
    setCompanyOptionsAux({
      requireSife: company.requireSife == "Sí" ? true : false,
      currentCompanyName: company.business_name || "",
      isITESMCompany: company.isITESMCompany || false,
    });
    if (company.isITESMCompany) {
      const result = await Promise.all([
        Methods.getCostCentersTec(),
        Methods.getGeneralistas(),
      ]);
      setCostCentersTec(result[0]);
      setGeneralistasTec(result[1]);
    }
  };

  const sortPolicies = (policies) => {
    policies.sort(function (a, b) {
      let momentA = a.contractStartDate.includes("/")
        ? moment(a.contractStartDate, "DD/MM/YYYY").startOf("day")
        : moment(a.contractStartDate.slice(0, 10)).startOf("day");
      let momentB = b.contractStartDate.includes("/")
        ? moment(b.contractStartDate, "DD/MM/YYYY").startOf("day")
        : moment(b.contractStartDate.slice(0, 10)).startOf("day");

      return momentA.isAfter(momentB) ? -1 : 1;
    });
    return policies;
  };

  const getAllInternPolicies = async () => {
    try {
      let policies = await Methods.getPolicyById(currentInternId);
      if (policies.policy.length) {
        let sortedPolicies = sortPolicies(policies.policy);
        setCurrentInsuranceData(sortedPolicies[0]);
        setInternPolicies(sortedPolicies);
        let now = moment().startOf("day");
        for (let policy of sortedPolicies) {
          let momentStart = policy.contractStartDate.includes("/")
            ? moment(policy.contractStartDate, "DD/MM/YYYY").startOf("day")
            : moment(policy.contractStartDate.slice(0, 10)).startOf("day");
          let momentEnd = policy.contractEndDate.includes("/")
            ? moment(policy.contractEndDate, "DD/MM/YYYY").startOf("day")
            : moment(policy.contractEndDate.slice(0, 10)).startOf("day");
          if (now.isSameOrAfter(momentStart) && now.isSameOrBefore(momentEnd)) {
            setCurrentInsuranceData(policy);
            break;
          }
        }
      }

      if (
        policies.policy.length > 0 &&
        policies.policy.some((policy) => "_" != policy.insuranceId)
      ) {
        setIsRenovationCase(true);
      }
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
    }
  };

  const curpValidation = async (curp) => {
    const regex =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    const isValid = curp.match(regex);
    if (curp.length > 0) {
      if (curp.length != 18 && curp.length > 0) {
        setIsCurpError(true);
        setCurpErrorLabel("El CURP debe tener mínimo 18 caracteres");
      } else {
        if (isValid) {
          setIsCurpError(false);
          setCurpErrorLabel("");

          let year = curp.substring(4, 6);
          const month = curp.substring(6, 8);
          const day = curp.substring(8, 10);
          if (year > 40) {
            year = 19 + year;
          } else {
            year = 20 + year;
          }
          const calculatedDate = moment([year, parseInt(month) - 1, day]);
          currentInternData.gender = curp.charAt(10);
          currentInternData.birthday = calculatedDate._d;
          currentInternData.age = moment().diff(calculatedDate, "years");
          currentInternData.rfc = curp.substring(0, 10);
          setInternDataAux({
            ...internDataAux,
            birthDate: calculatedDate._d,
            gender: curp.charAt(10),
            internAge: moment().diff(calculatedDate, "years"),
            rfc: curp.substring(0, 10),
          });
        } else {
          setIsCurpError(true);
          setCurpErrorLabel("El formato del CURP no es válido");
        }
      }
    } else {
      setIsCurpError(false);
      setCurpErrorLabel("");
    }
  };

  const getUniversities = async () => {
    try {
      let response = await axios({
        url: `${URL}/universities`,
        method: "PUT",
      });

      const url = response.data.data;
      let dataFile = await axios({
        url: url,
        method: "GET",
        responseType: "application/json",
      });
      let dataJSON = dataFile.data;

      setUniversities(dataJSON);
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
      setUniversities([]);
    }
  };

  const getAllInternContracts = async (currentContractIdByIntern) => {
    try {
      let internContractData = currentInternId
        ? await Methods.getAllInternContracts(currentInternId)
        : [];
      setInternContracts(internContractData);
      let currentContract = internContractData?.find(
        (contract) => contract._id == currentContractIdByIntern
      );
      setCurrentInternContract(currentContract);
      return currentContract;
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
    }
  };

  const verifyDataForm = async (
    key,
    typeError,
    formDataName,
    updateValue,
    bank
  ) => {
    if (typeError == "Email") {
      await verifyEmail(formDataName, key, updateValue);
    }
    if (typeError == "Phone") {
      await verifyPhone(formDataName, key, updateValue);
    }
    if (typeError == "Account") {
      let maxLength = 10;
      if (/^(Santander)$/i.test(bank)) maxLength = 11;
      else if (!/^(Bancomer|Banorte)$/i.test(bank)) maxLength = 18;
      await verifyAccount(formDataName, key, updateValue, maxLength);
    }
  };

  const verifyEmail = async (formDataName, key, updateValue) => {
    if (formDataName.length > 0) {
      if (!regexEmail.test(formDataName)) {
        updateKey(key, true);
        updateValue(true);
      } else {
        updateKey(key, false);
        updateValue(false);
      }
    } else {
      updateKey(key, false);
      updateValue(false);
    }
  };

  const onChangeEntry = (time, timeString) => {
    currentInternData.entry_time = timeString;
    setFormChanged(true);
  };

  const onChangeDepurate = (time, timeString) => {
    currentInternData.depurate_time = timeString;
    setFormChanged(true);
  };

  const verifyPhone = async (formDataName, key, updateValue) => {
    if (formDataName.length > 0) {
      if (formDataName.length == 10) {
        updateKey(key, false);
        updateValue(false);
      }
      if (formDataName.length < 10 && formDataName.length >= 1) {
        updateKey(key, true);
        updateValue(true);
      }
    } else {
      updateKey(key, false);
      updateValue(false);
    }
  };

  const verifyAccount = async (formDataName, key, updateValue, maxLength) => {
    if (formDataName.length > 0) {
      if (formDataName.length == maxLength) {
        updateKey(key, false);
        updateValue(false);
      }
      if (formDataName.length < maxLength && formDataName.length >= 1) {
        updateKey(key, true);
        updateValue(true);
      }
    } else {
      updateKey(key, false);
      updateValue(false);
    }
  };

  const updateKey = (keyToBeUpdated, valueToBeUpdated) => {
    let _data = formValidate;
    for (let key in _data) {
      if (key === keyToBeUpdated) {
        formValidate[key] = valueToBeUpdated;
      }
    }
    setFormValidate(_data);
  };

  const getInitialStartDate = (intern) => {
    let date = new Date(getFormatedDate(intern.contractEndDate));
    date.setDate(date.getDate() + 1);
    return date;
  };

  const getInternsDocuments = async () => {
    let intern = await Methods.getInternById(currentInternId);
    let currentContract = await getAllInternContracts(
      intern?.currentContractId
    );
    const endFormatedDate = moment(
      currentContract?.unsuscribeDate
        ? currentContract?.unsuscribeDate.substring(0, 10)
        : getFormatedDate(currentContract?.internEndDate)
    );
    const BIRTHDAY = intern.birthday
      ? new Date(getFormatedDate(intern.birthday))
      : new Date();
    setCurrentInternData(intern);
    setCurrentEntryTime(intern.entry_time || "12:00 pm");
    setCurrentDeputateTime(intern.depurate_time || "12:00 pm");
    await getCompanyById(intern.id_company);
    setMainBank(intern.bank);
    setCurrentContractId(intern.currentContractId || "");
    setCurrentMunicipality(intern.municipality);
    setContractToUpdateZipCode(intern.zip_code);
    setCurrentState(intern._state);
    setCurrentClabe(intern.clabe);
    setInternDataAux({
      ...internDataAux,
      contractEndDate: new Date(endFormatedDate),
      contractStartDate: new Date(
        getFormatedDate(currentContract.internStartDate)
      ),
      birthDate: BIRTHDAY,
      gender: intern.gender || "",
      internAge: intern.age || "",
      rfc: intern.rfc,
      requireInsurance: currentContract.requireInsurance || false,
      requireRegion: intern.region ? true : false,
      scholarshipAmount: parseFloat(currentContract.internAmount || 0),
      currentCollege: intern.college,
    });
    setRenewContractEndDate(new Date(getFormatedDate(intern.contractEndDate)));
    setRenewContractStartDate(getInitialStartDate(intern));
    if (intern.internStatus == "Inactivo") {
      setRenewContractEndDate(new Date(new Date().setHours(0, 0, 0, 0)));
      setRenewContractStartDate(new Date(new Date().setHours(0, 0, 0, 0)));
    }
    setLeaderInfo({
      currentLeaderEmail: intern.directBoss_email,
      currentLeaderName: intern.directBoss_name,
    });
    if (intern.department_id) {
      setCurrentDepartmentId(intern.department_id);
    }
    if (!intern.college) {
      setUniversitySelected(intern.college);
    }
  };

  const setLeader = async (department) => {
    let departmentName = "";
    let leaderEmail = "";
    let leaderFullName = "";
    currentInternData.directBoss_name = "";
    currentInternData.directBoss_email = "";
    let deparmentsArrayAux = currentCompany.isITESMCompany
      ? generalistasTec
      : departments;

    setLeaderInfo({
      currentLeaderEmail: "",
      currentLeaderName: "",
    });

    deparmentsArrayAux.forEach((element) => {
      if (element._id == department) {
        departmentName = element.title;
        if (element.leader?.length) {
          leaderEmail = element.leader[0].email;
          leaderFullName = element.leader[0].fullName;
        }
      }
    });

    setLeaderInfo({
      currentLeaderEmail: leaderEmail,
      currentLeaderName: leaderFullName,
    });

    setCurrentInternData({
      ...currentInternData,
      directBoss_name: leaderFullName,
      directBoss_email: leaderEmail,
      department_name: departmentName,
    });

    if (!currentCompany.isITESMCompany && !leaderFullName && !leaderEmail) {
      notify(
        `Este ${context.getDepartmentField()} no cuenta con usuarios ${context.getGeneralistaField()}`,
        "error"
      );
    }
    setFormChanged(true);
    setReloadProgressBar(!reloadProgressBar);
  };

  const setNewLeader = (newLeaderId) => {
    if (currentCompany.isITESMCompany) {
      const [generalista] = generalistasTec.filter(
        (element) => element._id == newLeaderId
      );
      setCurrentInternData({
        ...currentInternData,
        directBoss_name: generalista.name,
        directBoss_email: generalista.email,
      });
      setLeaderInfo({
        currentLeaderEmail: generalista.email,
        currentLeaderName: generalista.name,
      });
    } else {
      let [currentDepartment] = departments.filter(
        (element) => element._id == currentDepartmentId
      );
      let [currentLeaderData] = currentDepartment?.leader?.filter(
        (leaderAux) => leaderAux._id == newLeaderId
      );

      if (currentLeaderData) {
        setCurrentInternData({
          ...currentInternData,
          directBoss_name: currentLeaderData.fullName,
          directBoss_email: currentLeaderData.email,
        });

        setLeaderInfo({
          currentLeaderEmail: currentLeaderData.email,
          currentLeaderName: currentLeaderData.fullName,
        });
      }
    }
  };

  const getFormatedDate = (dateString) => {
    const regexDate = new RegExp(
      /^(\d{4})(-)(0[1-9]|1[0-2])\2([0-2][0-9]|3[0-1])/
    );
    let result;
    let year;
    let month;
    let day;
    if (regexDate.test(dateString)) {
      const newDateArray = dateString.split("-");
      year = newDateArray[0];
      month = addZeroToDate(newDateArray[1]);
      day = addZeroToDate(newDateArray[2]);
      result = month + "/" + day + "/" + year;
    } else {
      const newDateArray = dateString.split("/");
      month = addZeroToDate(newDateArray[0]);
      day = addZeroToDate(newDateArray[1]);
      result = day + "/" + month + "/" + newDateArray[2];
    }
    return result;
  };

  const addZeroToDate = (value) => {
    if (value.length == 1) {
      return "0" + value;
    } else {
      return value;
    }
  };

  const getInternDocs = async () => {
    try {
      let response = await axios({
        url: `${URL}/interns/docs`,
        method: "PUT",
        data: { intern_id: currentInternId },
      });
      const docs = response.data.docs;
      setInternDocs(docs);
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
      setInternDocs([]);
    }
  };

  const getDepartments = async () => {
    try {
      let response = await axios({
        url: `${URL}/departments`,
        method: "PUT",
        data: {
          id_company: context.user.company._id,
          departmentField: context.getDepartmentField(),
        },
      });
      verifyDepartments(response.data.data);
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
      setDepartments([]);
    }
  };

  const verifyDepartments = async (departments) => {
    let availableDepartments = [];
    await departments.forEach((element) => {
      availableDepartments.push(element);
    });
    await setDepartments(availableDepartments);
  };

  const getDataByZipCode = async (currentCP) => {
    let api = (await Methods.getApiData("sepomex"))[0];
    api.apiKey = JSON.parse(decrypt(api.apiKey));
    try {
      let response = await axios({
        url: `${api.apiLink}${currentCP}?token=${api.apiKey}`,
        method: "GET",
      });
      await setZipCodeData(response.data);
    } catch (e) {
      e.data && notify(e.data.response.message, "error");
    }
  };

  const setZipCodeData = async (data) => {
    let suburb = [];
    if (data.length > 0) {
      currentInternData.municipality = data[0].response.municipio;
      currentInternData._state = data[0].response.estado;
      setCurrentMunicipality(data[0].response.municipio);
      setCurrentState(data[0].response.estado);
      await data.forEach((element) => {
        const data = element.response.asentamiento;
        suburb.push(data);
      });
      suburb.sort();
    }
    setCurrentSuburbs(suburb);
  };

  const getInternsByClabe = async (data) => {
    if (
      data.target.elements.clabe.value !== "" &&
      data.target.elements.clabe.value !== "_"
    ) {
      let duplicated;
      duplicated = await Methods.getInternsByClabe(
        data.target.elements.clabe.value
      );
      for (let intern of duplicated.data.interns) {
        if (intern._id == currentInternData._id) {
          return false;
        }
      }
      if (duplicated && duplicated.data.total > 0) {
        notify(
          "Cuenta o clabe interbancaria existente. Favor de revisar la información.",
          "error"
        );
        return true;
      }
    }
    return false;
  };

  const getInternsByCurp = async (data) => {
    let duplicatedCompany = false;
    if (
      data.target.elements.curp.value !== "" &&
      data.target.elements.curp.value !== "_"
    ) {
      let duplicated;
      duplicated = await Methods.getInternsByCurp(
        data.target.elements.curp.value
      );
      for (let intern of duplicated.data.interns) {
        if (intern._id == currentInternData._id) {
          return false;
        }
        if (intern.id_company == currentInternData.id_company)
          duplicatedCompany = true;
      }
      if (duplicatedCompany) {
        notify(
          "No se puede guardar porque el CURP ya está dado de alta en la empresa.",
          "error"
        );
        return true;
      }
    }
    return false;
  };

  const getInternsByEmail = async (data) => {
    if (
      data.target.elements.email.value !== "" &&
      data.target.elements.email.value !== "_"
    ) {
      let duplicated;
      duplicated = await Methods.getInternsByEmail(
        data.target.elements.email.value
      );
      for (let intern of duplicated.data.interns) {
        if (intern._id == currentInternData._id) {
          return false;
        }
      }
      if (duplicated && duplicated.data.total > 0) {
        notify(
          "El correo de este practicante ha sido registrado previamente",
          "error"
        );
        return true;
      }
    }
    return false;
  };
  const getAllUsersByEmail = async (data) => {
    if (
      data.target.elements.email.value !== "" &&
      data.target.elements.email.value !== "_"
    ) {
      let duplicated;
      duplicated = await Methods.getAllUsersByEmail(
        data.target.elements.email.value
      );
      for (let intern of duplicated.data.users) {
        if (intern._id == currentInternData._id) {
          return false;
        }
      }
      if (duplicated && duplicated.data.total > 0) {
        notify(
          "El correo de este practicante ha sido registrado previamente como usuario",
          "error"
        );
        return true;
      }
    }
    return false;
  };
  const handleNewDate = (date, contractCase) => {
    let momentAux = moment(date);
    let momentPolicy = currentInsuranceData.contractStartDate.includes("/")
      ? moment(currentInsuranceData.contractStartDate, "DD/MM/YYYY").startOf(
          "day"
        )
      : moment(currentInsuranceData.contractStartDate.slice(0, 10)).startOf(
          "day"
        );
    let arrayAux = new Array(...internContracts);
    if (contractCase == "start") {
      if (
        Math.abs(momentAux.diff(moment(), "days")) >= 30 &&
        requireInsurance
      ) {
        notify(
          "Para un cambio mayor a 30 días, se tiene que invalidar el convenio y crear uno nuevo con las fechas correctas",
          "error"
        );
        return;
      }
      if (moment().diff(momentAux, "days") > 8 && requireInsurance) {
        notify(
          "Anterior a 8 días de la fecha actual, ya no es posbile realizar cambios.",
          "error"
        );
        return;
      }

      const [invalidContracts] = arrayAux.filter(
        (contract) =>
          contract.contractStatus == "Inactivo" &&
          currentInternContract._id != contract._id
      );
      if (invalidContracts) {
        let contractStartDate = invalidContracts.unsuscribeDate
          ? moment(invalidContracts.unsuscribeDate.substring(0, 10))
          : moment(invalidContracts.internEndDate, "DD/MM/YYYY");
        if (contractStartDate.isSameOrAfter(momentAux)) {
          notify(
            "La fecha inicio no puede sobreponerse con la de otro convenio",
            "error"
          );
          return;
        }
      }

      setInternDataAux({ ...internDataAux, contractStartDate: date });
      if (Math.abs(momentAux.diff(momentPolicy, "days")) >= 7)
        setDaysMoreThan7(true);
    }

    if (contractCase == "end") {
      const contractStartDateAux = moment(contractStartDate).subtract(
        1,
        "days"
      );
      const monthDiffernce = momentAux.diff(
        contractStartDateAux,
        "months",
        true
      );
      let maxMonths = isITESMCompany ? 12 : 6;

      if (monthDiffernce > maxMonths) {
        notify(`El convenio no puede ser mayor a ${maxMonths} meses`, "error");
        return;
      }

      const [posteriorContract] = arrayAux.filter(
        (contract) =>
          (contract.contractStatus == "Pendiente" ||
            contract.contractStatus == "En proceso") &&
          currentInternContract._id != contract._id
      );
      if (posteriorContract) {
        let contractStartDate = moment(
          posteriorContract.internStartDate,
          "DD/MM/YYYY"
        );
        if (contractStartDate.isSameOrBefore(momentAux)) {
          notify(
            "La fecha fin no puede sobreponerse con la de otro convenio",
            "error"
          );
          return;
        }
      }
      setInternDataAux({ ...internDataAux, contractEndDate: date });
    }
    setFormChanged(true);
    setDateChanged(true);
  };

  const updateIntern = async (e) => {
    e.preventDefault();
    setFormChanged(false);
    if (!canEditIntern()) {
      notify(
        "No tienes acceso a esta funcionalidad, solicítalo a un administrador",
        "error"
      );
      return;
    }

    setIsLoading(true);
    let data = {
      _id: currentInternId,
      attributes: [],
      emailCase:
        currentInternData.internStatus == "Activo" ||
        currentInternData.internStatus == "Pendiente"
          ? "Update"
          : undefined,
      updateClabe: false,
    };
    let contractData;
    let isInvalidation = false;

    await getDataToUpdate(e, data);
    let isError = await returnErrorsBeforeSave(data.attributes);
    let emailError = await getInternsByEmail(e);
    let userEmailError = await getAllUsersByEmail(e);
    let internClabeError = await getInternsByClabe(e);
    let internCurpError = await getInternsByCurp(e);
    if (
      isError ||
      emailError ||
      userEmailError ||
      internClabeError ||
      internCurpError
    ) {
      setIsLoading(false);
      return;
    }

    if (currentInternData.internStatus == "Inactivo") {
      notify(
        "El practicante cuenta con un convenio inactivo y es necesario crear un nuevo convenio",
        "error"
      );
      contractData = await getCurrentContractDataToUpdate(data);
      setNewInternContractData(contractData);
      setDataToUpdateByIntern(data);
      setIsNewCurrentContractIntern(true);
      setShowRenewIntern(true);
      return;
    }

    if (currentInternContract) {
      contractData = await getCurrentContractDataToUpdate(data);
      if (contractData.attributes.length) {
        if (
          (currentInternContract.contractStatus == "Activo" ||
            currentInternContract.contractStatus == "Pendiente") &&
          !isNewContract &&
          validateDataContractToUpdate(contractData.attributes)
        ) {
          setIsNewContract(false);
          setShowCreateNewContract(true);
          setIsLoading(false);
          return;
        }
      }
    }

    if (dateChanged) {
      let startDateAux;
      let endDateAux;
      let contractDuration;
      if (
        currentInternContract.contractStatus == "Activo" ||
        currentInternContract.contractStatus == "Pendiente"
      ) {
        if (daysMoreThan7 && !confirmation && requireInsurance) {
          setConfirmation(false);
          setShowVerifyChangeOnDate(true);
          setIsLoading(false);
          return;
        }
        if (!dateConfirm) {
          if (
            internDataAux.contractStartDate !==
            currentInternData.contractStartDate
          ) {
            if (
              moment(internDataAux.contractStartDate, "DD/MM/YYYY")
                .startOf("day")
                .isSameOrBefore(moment().startOf("day"))
            ) {
              data.attributes.push({
                updateKey: "internStatus",
                updateValue: "Activo",
              });
              contractData.attributes.push({
                updateKey: "contractStatus",
                updateValue: "Activo",
              });
            } else {
              data.attributes.push({
                updateKey: "internStatus",
                updateValue: "Pendiente",
              });
              contractData.attributes.push({
                updateKey: "contractStatus",
                updateValue: "Pendiente",
              });
            }
            data.lastStatus = internDataAux.internStatus;
          }

          if (
            internDataAux.contractEndDate !== currentInternData.contractEndDate
          )
            setInternDataAux({
              ...internDataAux,
              contractEndDate: internDataAux.contractEndDate,
            });
          setDaysMoreThan7(false);
        }

        if (requireInsurance) {
          if (currentInsuranceData.paymentType == "Mensual") {
            let arrayAux = internPolicies.filter((policy) => {
              return policy.contractId == currentContractId;
            });
            for (let item of arrayAux) {
              await deleteInsuranceIdAndPolicies(
                item,
                currentInternData,
                currentCompany,
                isInvalidation
              );
            }
          } else {
            await deleteInsuranceIdAndPolicies(
              currentInsuranceData,
              currentInternData,
              currentCompany,
              isInvalidation
            );
          }
        }

        startDateAux =
          typeof internDataAux.contractStartDate == "string"
            ? moment(internDataAux.contractStartDate, "DD/MM/YYYY")
            : moment(internDataAux.contractStartDate);
        endDateAux =
          typeof internDataAux.contractStartDate == "string"
            ? moment(internDataAux.contractEndDate, "DD/MM/YYYY")
            : moment(internDataAux.contractEndDate);
        contractDuration = Math.ceil(
          endDateAux.diff(startDateAux, "months", true)
        );
        data.attributes.push({
          updateKey: "contractDuration",
          updateValue: contractDuration,
        });
      } else if (currentInternContract.contractStatus == "En proceso") {
        startDateAux =
          typeof internDataAux.contractStartDate == "string"
            ? moment(internDataAux.contractStartDate, "DD/MM/YYYY")
            : moment(internDataAux.contractStartDate);
        endDateAux =
          typeof internDataAux.contractEndDate == "string"
            ? moment(internDataAux.contractEndDate, "DD/MM/YYYY")
            : moment(internDataAux.contractEndDate);
        contractDuration = Math.ceil(
          endDateAux.diff(startDateAux, "months", true)
        );
        contractData.attributes.push({
          updateKey: "internContractDuration",
          updateValue: contractDuration,
        });
        if (requireInsurance) {
          contractData.insuranceId = currentInsuranceData._id;
          contractData.changeInsuranceDate = true;
        }
      }
    }

    verifySifeForceReload(data, contractData);
    await Promise.all([
      Methods.updateIntern(data),
      isNewContract
        ? renewContract({
            isLastContractInvalid: true,
            lastContract: contractData,
            renewUnsuscribedInternData: undefined,
            requireInsuranceAux: requireInsurance,
          })
        : Methods.updateContractById(contractData),
      data.updateClabe &&
        (await Methods.updatePrefactureClabeByIntern(
          data._id,
          context.user.data.isCaintraUser ? "#FF2D00" : "#28B463",
          context.user.data.email,
          e.target.elements.clabe.value
        )),
      data.updateClabe /* && await createClabeRecord(data, e.target.elements.clabe.value) */,
    ])
      .then(async () => {
        await createClabeRecord(data, e.target.elements.clabe.value);
      })
      .catch(async () => {
        data._id = "123abc_error";
        await createClabeRecord(data, e.target.elements.clabe.value);
      });

    notify(
      `Los datos de ${currentInternData.firstname} ${currentInternData.father_lastname} se actualizaron correctamente`,
      "success"
    );
    setDateChanged(false);
    setUpdateInfo(!updateInfo);
    setIsNewContract(false);
  };

  const createClabeRecord = async (intern, clabe) => {
    let company = "";
    if (intern.id_company) {
      company = intern.id_company;
    } else {
      company = currentInternData.id_company;
    }
    let record = {
      createdAt: moment().format(),
      createdAtTimestamp: moment().format("X"),
      authorEmail: context.user.data.email,
      authorName: context.user.data.fullName,
      internId: intern._id,
      internAccount: clabe,
      internCompanyId: company,
      internFullName:
        currentInternData.firstname +
        " " +
        currentInternData.father_lastname +
        " " +
        currentInternData.mother_lastname,
    };

    await Methods.createInternLogisticRecord(record);
  };

  const verifySifeForceReload = (data, contractData) => {
    data = streetValidation(data, "street");
    contractData = streetValidation(contractData, "internStreet");
  };

  const streetValidation = (data, key) => {
    let attributes = [];

    for (let value of data.attributes) {
      if (value.updateKey != key) {
        attributes.push(value);
      }
      if (value.updateKey == key) {
        value.updateValue =
          value.updateValue?.substring(0, 1) == "-" ||
          value.updateValue?.substring(0, 1) == "="
            ? value.updateValue.substring(1)
            : value.updateValue;
        currentInternData.street = value.updateValue;
        attributes.push(value);
      }
    }
    return attributes;
  };

  const validateDataContractToUpdate = (valuesToUpdate) => {
    let requiredKeysToInvalidate = [
      "internFirstName",
      "internFatherLastname",
      "internMotherLastname",
      "internEndDate",
      "internStartDate",
      "internEmail",
      "internAmount",
      "internCareer",
      "collegeName",
      "schoolEnrollment",
      "internScholarship",
    ];

    for (let value of valuesToUpdate) {
      let result = requiredKeysToInvalidate.find(
        (element) => element == value.updateKey
      );
      if (result && result != undefined) return true;
      if (
        value.updateKey == "internStreet" &&
        (value.updateValue?.substring(0, 1) == "-" ||
          value.updateValue?.substring(0, 1) == "=")
      ) {
        if (value.updateValue?.substring(0, 1) == "=") {
          setContractUpdateEqual(true);
        }
        return true;
      }
    }

    return false;
  };

  const getExtension = (fileName) => {
    let basename = fileName.split(/[\\/]/).pop();
    let pos = basename.lastIndexOf(".");

    if (basename === "" || pos < 1) {
      return "";
    }

    return basename.slice(pos + 1);
  };

  const uploadSignatureDocument = async (file, fileName, fileKey) => {
    try {
      const _file = file;
      const newFileName = `${fileName}.pdf`;
      let data = new FormData();

      const toBase64 = (_file) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(_file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });

      const base64file = await toBase64(_file);
      let files = Array();
      let currentFile = {
        base64file: base64file,
        filename: newFileName,
        fileKey: fileKey,
      };
      files.push(currentFile);
      data.append("files", JSON.stringify(files));
      data.append("path", `interns/docs/${currentInternId}`);
      data.append("intern_id", currentInternId);

      if (_file.size > 10485760) {
        notify(`El archivo ${_file.name} pesa más de 10 MB`, "error");
        return;
      }

      await axios({
        url: `${URL}/interns/docs/${currentInternId}/`,
        method: "POST",
        data: data,
      });

      notify(`El convenio se generó con éxito`, "success");
      setUpdateInfo(!updateInfo);
    } catch (error) {
      error.response && notify(error.response.data.message, "error");
    }
  };

  const returnErrorsBeforeSave = (dataToUpdate) => {
    let dontSave = false;

    if (isEmailError) {
      notify(
        "El correo ingresado en información general no es válido",
        "error"
      );
      dontSave = true;
    }
    if (isPhoneError) {
      notify(
        "El teléfono ingresado en información proporcionada por el practicante debe tener 10 dígitos",
        "error"
      );
      dontSave = true;
    }
    if (isEmailLeaderError) {
      notify(
        `El correo ingresado en ${context.getGeneralistaField()} no es válido`,
        "error"
      );
      dontSave = true;
    }
    if (isAccountError) {
      notify("El número de cuenta ingresado debe tener 10 dígitos", "error");
      dontSave = true;
    }
    if (isCurpError) {
      notify("El CURP no es valido", "error");
      dontSave = true;
    }
    if (dataToUpdate.length == 0) {
      notify("No hay cambios por realizar", "error");
      dontSave = true;
    }

    return dontSave;
  };

  const getDataToUpdate = (e, data) => {
    const bankAux = {
      bancomer: "bancomerData",
      banorte: "banorteData",
      santander: "santanderData",
      interbancaria: "internBankData",
    };
    const leaderIdAux = e.target.elements.directBoss_name.value;
    let departmentsArrayAux = context.user.company.isITESMCompany
      ? generalistasTec
      : departments;
    let [currentDepartment] = departmentsArrayAux.filter(
      (element) =>
        element._id ==
        (currentCompany.isITESMCompany ? leaderIdAux : currentDepartmentId)
    );
    let [currentLeaderData] =
      currentLeaderName && currentDepartment?.leader !== undefined
        ? currentDepartment?.leader?.filter(
            (leaderAux) => leaderAux._id == leaderIdAux
          )
        : [];
    e.target.elements.firstname.value =
      e.target.elements.firstname.value.toUpperCase();
    e.target.elements.father_lastname.value =
      e.target.elements.father_lastname.value.toUpperCase();
    e.target.elements.mother_lastname.value =
      e.target.elements.mother_lastname.value.toUpperCase();
    e.target.elements.email.value = e.target.elements.email.value.toLowerCase();
    e.target.elements.scolarshipAmount.value = (
      parseFloat(e.target.elements.scolarshipAmount.value) || 0
    ).toFixed(2);

    const availableKeys = [
      "firstname",
      "father_lastname",
      "mother_lastname",
      "email",
      "directBoss_email",
      "projectName",
      "scolarshipAmount",
      "bank",
      "department_id",
      "curp",
      "_state",
      "college",
      "career",
      "school_enrollment",
      "street",
      "municipality",
      "grade",
      "int_number",
      "clabe",
      "phone",
      "zip_code",
      "period_type",
      "_group",
      "ext_number",
      "suburb",
      "age",
      "birthday",
      "entry_time",
      "depurate_time",
      "linkedin",
      "instagram",
      "scholarship",
      "rfc",
      "faculty",
      "leaderTec",
      "position",
      "region",
      "nomina",
      "program",
    ];
    const invalidKeys = ["_state", "_group", "region", "position"];
    for (let index = 0; index < e.target.elements.length; ++index) {
      let key = e.target.elements[index].id;
      let value = e.target.elements[index].value;
      if (key.substring(0, 3) == "rw_") {
        if (e.target.elements[index].name == "birthday") {
          key = "birthday";
        }
      }
      if (key === "faculty") {
        value = validateJson(value);
      }
      if (availableKeys.includes(key)) {
        if (invalidKeys.includes(key)) {
          data.attributes.push({
            updateKey: key,
            updateValue: value,
            expressionAttributeName: `#${key}`,
          });
        } else if (key === "clabe" && value === "") {
          data.attributes.push({
            updateKey: key,
            updateValue: "_",
          });
        } else if (key === "clabe") {
          const bank = currentInternData.bank.toLowerCase();
          const aux = bankAux[bank] ? bankAux[bank] : "internBankData";
          let bankData = currentInternData[aux] ? currentInternData[aux] : {};
          bankData.accountNumber = value;
          data.attributes.push({
            updateKey: key,
            updateValue: value,
          });
          data.attributes.push({
            updateKey: aux,
            updateValue: bankData,
          });
        } else if (key === "curp" && value !== "" && value !== "_") {
          data.attributes.push({
            updateKey: key,
            updateValue: value,
          });
          data.attributes.push({
            updateKey: "gender",
            updateValue: value.charAt(10),
          });
        } else {
          data.attributes.push({
            updateKey: key,
            updateValue: value,
          });
        }
      }
    }

    currentInternData.entry_time &&
      data.attributes.push({
        updateKey: "entry_time",
        updateValue: currentInternData.entry_time,
      });

    currentInternData.depurate_time &&
      data.attributes.push({
        updateKey: "depurate_time",
        updateValue: currentInternData.depurate_time,
      });

    setChangedValues(data, e, currentLeaderData, currentDepartment);
  };

  const setChangedValues = (data, e, currentLeaderData, currentDepartment) => {
    const optionIndex = e.target.elements.department_id.selectedIndex;
    const department_name =
      e.target.elements.department_id.children[optionIndex].id;
    const department_id =
      e.target.elements.department_id.children[optionIndex].value ||
      currentDepartmentId;
    const optionIndexCostCenter =
      e.target.elements.cost_center_name.selectedIndex;
    const cost_center_name =
      e.target.elements.cost_center_name.children[optionIndexCostCenter].id;
    const currentContractStartDate = currentInternData.contractStartDate;
    const currentContractEndDate = currentInternData.contractEndDate;
    const updatedContractEndDate = moment(internDataAux.contractEndDate).format(
      "DD/MM/YYYY"
    );
    const updatedContractStartDate = moment(
      internDataAux.contractStartDate
    ).format("DD/MM/YYYY");
    const faculty_name =
      universitySelected == "Otro" ? "Otro" : currentInternData.faculty;
    const career_name =
      universitySelected == "Otro" ? "Otro" : currentInternData.career;

    if (currentContractStartDate != updatedContractStartDate) {
      data.attributes.push({
        updateKey: "contractStartDate",
        updateValue: updatedContractStartDate,
      });
    }

    if (currentContractEndDate != updatedContractEndDate) {
      data.attributes.push({
        updateKey: "contractEndDate",
        updateValue: updatedContractEndDate,
      });
    }

    if (department_name) {
      data.attributes.push({
        updateKey: "department_name",
        updateValue: department_name,
      });
    }

    if (department_id) {
      data.attributes.push({
        updateKey: "department_id",
        updateValue: department_id,
      });
    }

    data.attributes.push({
      updateKey: "directBoss_name",
      updateValue: context.user.company.isITESMCompany
        ? currentDepartment?.name
        : currentLeaderData?.fullName || "",
    });

    if (faculty_name == "" && career_name == "") {
      data.attributes.push(
        { updateKey: "faculty", updateValue: "" },
        { updateKey: "career", updateValue: "" }
      );
    }

    if (
      cost_center_name != currentInternData.cost_center_name &&
      cost_center_name != ""
    ) {
      data.attributes.push({
        updateKey: "cost_center_name",
        updateValue: cost_center_name,
      });
    }

    if (currentClabe != e.target.elements.clabe.value) {
      data.attributes.push(
        {
          updateKey: "accountColorStatus",
          updateValue: context.user.data.isCaintraUser ? "#FF2D00" : "#28B463",
        },
        { updateKey: "lastModifyAccount", updateValue: context.user.data.email }
      );
      data.updateClabe = true;
    }

    if (
      (currentClabe == "_" || currentClabe?.trim() == "") &&
      e.target.elements.clabe.value?.trim() != ""
    ) {
      data.attributes.push({
        updateKey: "logisticOrigin",
        updateValue: context.user.data.isCaintraUser ? "Asesora" : "Empresa",
      });
    }

    if (
      scholarshipAmount !=
        parseFloat(e.target.elements.scolarshipAmount.value) &&
      currentInternContract.contractStatus == "Activo"
    )
      data.lastScholarshipAmount = scholarshipAmount;

    if (
      currentCollege != parseFloat(e.target.elements.college.value) &&
      currentInternContract.contractStatus == "Activo"
    )
      data.lastCollege = currentCollege;
  };

  const validateJson = (value) => {
    try {
      return JSON.parse(value).name;
    } catch (error) {
      return value;
    }
  };

  const getCurrentContractDataToUpdate = async (dataToEvaluate) => {
    let data = {
      _id: currentContractId,
      attributes: [],
      emailCase:
        currentInternData.internStatus == "Activo" ||
        currentInternData.internStatus == "Pendiente"
          ? "Update"
          : undefined,
    };

    let contractValuesArray = [
      "college",
      "school_enrollment",
      "career",
      "street",
      "ext_number",
      "suburb",
      "municipality",
      "phone",
      "email",
      "contractStartDate",
      "contractEndDate",
      "scolarshipAmount",
      "department_name",
      "firstname",
      "father_lastname",
      "mother_lastname",
      "curp",
      "scholarship",
      "directBoss_name",
      "faculty",
    ];

    if (
      currentInternContract.contractStatus == "Invalidado" ||
      currentInternContract.contractStatus == "Baja"
    ) {
      return;
    }

    for (let value of dataToEvaluate.attributes) {
      let isContractValue = contractValuesArray.find(
        (object) => object == value.updateKey
      );
      if (
        isContractValue &&
        isContractValue != "contractStartDate" &&
        isContractValue != "contractEndDate"
      ) {
        pushValueToUpdate(isContractValue, data, value.updateValue);
      } else {
        pushValueToUpdate(
          isContractValue,
          data,
          moment(value.updateValue, "DD/MM/YYYY")
        );
      }
    }
    await pushFacultyValues(data);
    await pushCompanyValues(data);
    return data;
  };

  const pushValueToUpdate = (isContractValue, data, valueToUpdate) => {
    let value = {
      college: "collegeName",
      school_enrollment: "schoolEnrollment",
      career: "internCareer",
      street: "internStreet",
      ext_number: "internExtNumber",
      suburb: "internSuburb",
      municipality: "internMunicipality",
      phone: "internPhone",
      email: "internEmail",
      contractStartDate: "internStartDate",
      contractEndDate: "internEndDate",
      scolarshipAmount: "internAmount",
      department_name: "departmentName",
      firstname: "internFirstName",
      father_lastname: "internFatherLastname",
      mother_lastname: "internMotherLastname",
      curp: "internCurp",
      scholarship: "internScholarship",
      directBoss_name: "directBoss",
      faculty: "internFaculty",
    };

    if (
      value[isContractValue] &&
      currentInternContract[value[isContractValue]] != valueToUpdate
    ) {
      let updateValueAux = valueToUpdate;
      if (
        value[isContractValue] == "internEndDate" ||
        (value[isContractValue] == "internStartDate" &&
          typeof valueToUpdate != "string")
      ) {
        updateValueAux = moment(valueToUpdate, "DD/MM/YYYY").format(
          "DD/MM/YYYY"
        );
      }
      data.attributes.push({
        updateKey: value[isContractValue],
        updateValue: updateValueAux,
      });
    }
  };

  const pushCompanyValues = async (data) => {
    let result = await Methods.getCompanies([currentInternData.id_company]);
    let companyData = result[0];
    let companyName = companyData.business_name;
    let companyAdminPhone = companyData.org_admin_phone;
    let companyAddress =
      companyData.street +
      " " +
      companyData.ext_number +
      " " +
      companyData.zip_code +
      " " +
      companyData.suburb +
      " " +
      companyData.municipality +
      " " +
      companyData.state;
    if (currentInternContract.companyName != companyName)
      data.attributes.push({
        updateKey: "companyName",
        updateValue: companyName,
      });
    if (currentInternContract.companyAdminPhone != companyAdminPhone)
      data.attributes.push({
        updateKey: "companyAdminPhone",
        updateValue: companyAdminPhone,
      });
    if (currentInternContract.companyAddress != companyAddress)
      data.attributes.push({
        updateKey: "companyAddress",
        updateValue: companyAddress,
      });
  };

  const pushFacultyValues = async (data) => {
    let collegeSeleted = currentInternContract.collegeName;
    let facultySelected;
    for (let value of data.attributes) {
      if (value.updateKey == "collegeName") {
        collegeSeleted = value.updateValue;
      }
      if (value.updateKey == "internFaculty") {
        facultySelected = value.updateValue;
      }
    }

    let collegeContact = await setUniversityContact(
      collegeSeleted,
      facultySelected
    );
    if (collegeContact && collegeContact.email) {
      if (
        currentInternContract.collegeContact &&
        currentInternContract.collegeContact.email != collegeContact.email
      ) {
        data.attributes.push({
          updateKey: "collegeContact",
          updateValue: collegeContact,
        });
      } else if (!currentInternContract.collegeContactEmail) {
        data.attributes.push({
          updateKey: "collegeContact",
          updateValue: collegeContact,
        });
      }
    }
  };

  const renewContract = async ({
    isLastContractInvalid,
    lastContract,
    renewUnsuscribedInternData,
    requireInsuranceAux,
  }) => {
    let result = await Methods.getCompanies([currentInternData.id_company]);
    let companyData = result[0];
    let universityData = {};
    if (currentInternData.college) {
      universityData = await setUniversityContact(
        currentInternData.college,
        currentInternData.faculty
      );
    }
    let data = {
      contractId: uuid.v1(),
      internId: currentInternData._id,
      collegeName: currentInternData.college,
      collegeContact: universityData || "",
      schoolEnrollment: currentInternData.school_enrollment,
      internScholarship: currentInternData.scholarship,
      internCareer: currentInternData.career || "",
      internFaculty: currentInternData.faculty,
      internStreet: currentInternData.street,
      internExtNumber: currentInternData.ext_number,
      internSuburb: currentInternData.suburb,
      internMunicipality: currentInternData.municipality,
      internPhone: currentInternData.phone,
      email: currentInternData.email,
      contractEndDate: renewUnsuscribedInternData
        ? renewUnsuscribedInternData[0].updateValue
        : currentInternData.contractEndDate,
      contractStartDate: renewUnsuscribedInternData
        ? renewUnsuscribedInternData[1].updateValue
        : currentInternData.contractStartDate,
      internContractDuration: renewUnsuscribedInternData
        ? renewUnsuscribedInternData[2].updateValue
        : currentInternData.contractDuration,
      internAmount: currentInternData.scolarshipAmount,
      companyName: companyData.business_name,
      companyAdminPhone: companyData.org_admin_phone,
      companyAddress:
        companyData.street +
        " " +
        companyData.ext_number +
        " " +
        companyData.zip_code +
        " " +
        companyData.suburb +
        " " +
        companyData.municipality +
        " " +
        companyData.state,
      isSifeDocument: requireSife,
      createdByFullName: context.user.data.fullName,
      createdByEmail: context.user.data.email,
      directBoss: currentInternData.directBoss_name,
      departmentName: currentInternData.department_name,
      id_company: companyData._id,
      companyId: companyData._id,
      internFirstName: currentInternData.firstname,
      internFatherLastname: currentInternData.father_lastname,
      internMotherLastname: currentInternData.mother_lastname,
      curp: currentInternData.curp,
      lastContractId: currentInternContract?._id,
      lastContract: currentInternContract,
      isLastContractInvalid: isLastContractInvalid || false,
      isFutureRenovation: renewUnsuscribedInternData
        ? renewUnsuscribedInternData[4].updateValue
        : false,
      contractStatus: isLastContractInvalid
        ? currentInternContract.contractStatus || "En proceso"
        : "En proceso",
      contractApprovalDate: isLastContractInvalid
        ? currentInternContract.contractApprovalDate || ""
        : "",
      currentInsuranceId: currentInsuranceData ? currentInsuranceData._id : "-",
      dateChanged: dateChanged,
      requireInsurance: requireInsuranceAux || false,
    };

    if (!currentInternData.requireInsurance && requireInsuranceAux) {
      data.activeInternInsurance = true;
    }

    if (requireSife) {
      data.signatureStatus = "Pendiente";
      data.lastSignatureReminder = moment().format("DD/MM/YYYY");
    }

    if (lastContract) {
      data = verifyCurrentChanges(data, lastContract);
    }

    if (dateChanged) {
      let startDateAux =
        typeof internDataAux.contractStartDate == "string"
          ? moment(internDataAux.contractStartDate, "DD/MM/YYYY")
          : moment(internDataAux.contractStartDate);
      let endDateAux =
        typeof internDataAux.contractStartDate == "string"
          ? moment(internDataAux.contractEndDate, "DD/MM/YYYY")
          : moment(internDataAux.contractEndDate);
      let contractDuration = Math.ceil(
        endDateAux.diff(startDateAux, "months", true)
      );
      data.internContractDuration = contractDuration;
      data.contractStartDate = moment(internDataAux.contractStartDate).format(
        "DD/MM/YYYY"
      );
      data.contractEndDate = moment(internDataAux.contractEndDate).format(
        "DD/MM/YYYY"
      );
    }

    await Methods.renewIntern(data);

    let startDate = moment(internDataAux.contractStartDate);
    let endDate = moment(internDataAux.contractEndDate);

    if (data.contractStatus == "Activo" || data.contractStatus == "Pendiente") {
      let totalDuration = Math.ceil(
        endDate.diff(moment(startDate), "months", true)
      );
      if (totalDuration > 6) totalDuration = 6;

      let newInsuranceData = {
        policyId: currentInsuranceData?._id,
        totalDuration: totalDuration,
        contractStartDate: startDate.format("DD/MM/YYYY"),
        contractEndDate: endDate.format("DD/MM/YYYY"),
        contractId: data.contractId,
        isPending: data.isFutureRenovation,
      };

      if (requireInsurance && dateChanged) {
        await Methods.setPolicies(newInsuranceData);
      }

      data._id = data.contractId;
      data.internStartDate = data.contractStartDate;
      data.internEndDate = data.contractEndDate;
      data.internEmail = data.internEmail ? data.internEmail : data.email;
      if (requireSife) {
        await sendDocs(data);
        await deleteUnsigned(data.lastContractId);
        await resendDocs(lastContract, data);
      }
    }
  };

  const resendDocs = async (data, currentContract) => {
    if (contractUpdateEqual && isITESMCompany) {
      await sendITESMdocs(currentContract);
    } else {
      for (let value of data.attributes) {
        if (value.updateKey == "internEmail" && isITESMCompany) {
          await sendITESMdocs(currentContract);
        }
      }
    }
  };
  const verifyCurrentChanges = (currentData, lastContractData) => {
    if (lastContractData.attributes.length) {
      for (let value of lastContractData.attributes) {
        if (
          value.updateKey != "internEndDate" ||
          value.updateKey == "internStartDate"
        ) {
          currentData[value.updateKey] = value.updateValue;
        }
      }
    }
    return currentData;
  };

  const createSignatureDocument = async (
    internContractData,
    signatureIntern,
    universityLogo,
    signatureUniversity,
    companyLogo,
    signatureCompany
  ) => {
    const doc = (
      <TemplatePDF
        internContractData={internContractData}
        companyLogo={companyLogo}
        signatureIntern={signatureIntern}
        universityLogo={universityLogo}
        signatureUniversity={signatureUniversity}
        signatureCompany={signatureCompany}
        context={context}
      />
    );
    const asPdf = pdf([]);
    asPdf.updateContainer(doc);
    const blob = asPdf.toBlob();
    return blob;
  };

  const downloadContract = async (contratData) => {
    const [company] = await Methods.getCompanies([
      currentInternContract.companyId,
    ]);
    let contractToDownload = contratData ? contratData : currentInternContract;
    let data = {
      universityLogo: "",
      getUniversitySignature: true,
      signatureUniversity: "",
      signatureIntern: "",
      universityData: "",
    };

    let body = {
      document_id: contractToDownload._id,
    };

    try {
      if (company?.logo_url) {
        if (!company.logo_url.includes("https")) {
          let res = company.logo_url.replace("http", "https");
          company.logo_url = res;
        }
      }

      if (contractToDownload.isSifeDocument)
        await getSignatures(contractToDownload, body, data);
      if (!company.logo_url)
        notify("La compañía no cuenta con logo para su descarga", "error");
      let blob = await createSignatureDocument(
        contractToDownload,
        data.signatureIntern,
        data.universityLogo,
        data.signatureUniversity,
        company.logo_url,
        data.signatureCompany
      );
      saveAs(blob, `convenio.pdf`);
    } catch (e) {
      notify("Algo ocurrió al momento de generar la descarga", "error");
    }
  };

  const getSignatures = async (contractToDownload, body, data) => {
    let signatureAux = "";
    let signatureAuxUniversity = "";
    data.universityData = await setUniversityContact(
      contractToDownload.college,
      contractToDownload.faculty
    );
    body.email = contractToDownload.internEmail;
    console.log("GETSIGNATURES---------------------------------");
    signatureAux = await Methods.getSignatureByEmail(body);
    //GET INTERN SIGNATURE
    if (signatureAux) {
      data.signatureIntern = getFormatedSignature(signatureAux);
    }
    //GET COLLEGE LOGO
    if (!contractToDownload.collegeContact) {
      data.signatureInternuniversityLogo = data.universityData.logo;
      data.getUniversitySignature = false;
    }

    if (data.getUniversitySignature) {
      body.email = contractToDownload.collegeContact.email
        ? contractToDownload.collegeContact.email
        : contractToDownload.collegeContact;
      signatureAuxUniversity = await Methods.getSignatureByEmail(body);
      if (signatureAuxUniversity) {
        data.signatureUniversity = getFormatedSignature(signatureAuxUniversity);
      }
      data.universityLogo = "";
    }
    if (
      contractToDownload.companyName ===
      "INSTITUTO TECNOLOGICO Y DE ESTUDIOS SUPERIORES DE MONTERREY"
    ) {
      body.email = "fatima.nava@caintra.org.mx";
      let signatureAuxCompany = await Methods.getSignatureByEmail(body);
      if (signatureAuxCompany) {
        data.signatureCompany = getFormatedSignature(signatureAuxCompany);
      }
    }

    showSignatureErrors(contractToDownload, data);
  };

  const getFormatedSignature = (signature) => {
    let signatures = [];
    let character = 38;
    let start = 0;
    for (let a = 0; a <= 4; a++) {
      let characterAux = character * (a + 1);
      signatures.push(signature.substring(start, characterAux));
      start += character;
    }
    return signatures;
  };

  const showSignatureErrors = (contractToDownload, data) => {
    if (!data.signatureIntern)
      notify("El practicante aún no firma ningún documento", "error");
    if (contractToDownload.collegeContact && !data.signatureUniversity)
      notify(
        "El contacto de universidad aún no firma ningún documento",
        "error"
      );
    if (!contractToDownload.collegeContact && !data.universityLogo)
      notify(
        "La universidad no cuenta con contacto ni con logo para la creación del convenio",
        "error"
      );
  };

  const setUniversityContact = async (currentUniversity, currentFaculty) => {
    if (currentUniversity == "Otro") return {};
    if (!currentUniversity) return {};
    let universitySelected;
    let universityContact = {};
    for (let university of universities) {
      if (university._name == currentUniversity) {
        universitySelected = university;
      }
    }
    if (universitySelected?.sign) {
      universityContact = {
        phone: universitySelected.phone,
        email: universitySelected.email,
        firstname: universitySelected.contactName,
        father_lastname: universitySelected.fatherLastName,
        mother_lastname: universitySelected.motherLastName,
      };
    } else {
      if (!universitySelected) return {};
      for (let faculty of universitySelected.faculties) {
        if (faculty._name == currentFaculty) {
          universityContact = {
            phone: faculty.phone,
            email: faculty.email,
            firstname: faculty.contactName,
            father_lastname: faculty.fatherLastName,
            mother_lastname: faculty.motherLastName,
          };
        }
      }
    }
    if (universitySelected?.logo_url)
      universityContact.logo = universitySelected?.logo_url;
    return universityContact;
  };

  const sendSignature = async (
    data,
    blob,
    key,
    universitySignature,
    docName,
    signers
  ) => {
    let blobBuffer = await blob.arrayBuffer();
    let buffer = Buffer.from(blobBuffer);

    const body = {
      signers: signers,
      rfcUser: false,
      docName: docName,
      docId: key,
      doc: buffer,
      hash: key,
      URL: URL + "/create/signature",
    };

    try {
      await axios({
        method: "POST",
        url: `${URL}/send/signature`,
        data: body,
      });

      if (universitySignature) {
        let contact = {
          email: data.email,
          name: data.contactName || "",
        };
        await axios({
          method: "POST",
          url: `${URL}/signature/notification/university`,
          data: contact,
        });
      }

      return true;
    } catch (e) {
      notify("Hubo un problema al enviar la documentación a SIFE", "error");
      return false;
    }
  };

  const blobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const verifyContractStatus = () => {
    if (internContracts.length) {
      for (let contract of internContracts) {
        if (
          contract.contractStatus == "En proceso" ||
          contract.contractStatus == "Pendiente"
        )
          return false;
      }
    }
    return true;
  };

  const getColorByStatus = (color) => {
    let colorToReturn = {
      "En proceso": "#FF9900",
      Pendiente: "#25EB51",
      Activo: "#25EB51",
      Inactivo: "#ED5A6C",
      Invalidado: "Black",
    };
    return colorToReturn[color] || "Black";
  };

  const getErrorText = (key) => {
    let insuranceText = {
      planType: "el tipo de plan",
      berneficiary: "el beneficiario",
      relationship: "la relación con el beneficiario",
      percent: "el porcentaje de cobertura al beneficiario",
      organicLossesAux: "las pérdidas orgánicas",
      policyCostAux: "el costo de póliza",
    };
    return insuranceText[key];
  };

  const verifyDataToSend = (data) => {
    let internKeys = [
      "duration",
      "insuredName",
      "streetNumberIntern",
      "suburbIntern",
      "college",
      "scholarship",
      "curp",
      "phone",
      "career",
      "scolarshipAmount",
      "email",
      "currentInternId",
      "internRfc",
    ];
    let internErrors = 0;

    let insuranceKeys = [
      "paymentStatus",
      "planType",
      "startValidity",
      "font",
      "berneficiary",
      "relationship",
      "percent",
    ];
    let insuranceErrors = 0;
    let policyKeys = ["organicLossesAux", "policyCostAux"];

    let companyKeys = ["company", "companyRfc", "streetNumber", "suburb"];
    let companyErrors = 0;

    for (let key in data) {
      if (internKeys.includes(key) && (!data[key] || data[key] == "_"))
        internErrors++;
      if (insuranceKeys.includes(key) && !data[key]) {
        insuranceErrors++;
        notify(
          "Hace falta llenar " +
            getErrorText(key) +
            " en el seguro del practicante para continuar",
          "error"
        );
      }
      if (companyKeys.includes(key) && !data[key]) companyErrors++;
    }

    for (let policyKey of policyKeys) {
      if (!data.hasOwnProperty(policyKey) || !data[policyKey]) {
        notify(
          "Hace falta llenar " +
            getErrorText(policyKey) +
            " en el seguro del practicante para continuar",
          "error"
        );
        insuranceErrors++;
      }
    }

    if (
      !data.secondBerneficiary &&
      (data.secondPercent == 0 || !data.secondPercent)
    ) {
      insuranceErrors = insuranceErrors - 3;
    }

    internErrors > 0 &&
      notify(
        "Todavía no llenas la información necesaria en el practicante para continuar",
        "error"
      );
    insuranceErrors > 0 &&
      notify(
        "Todavía no llenas la información necesaria en el seguro del practicante para continuar",
        "error"
      );
    companyErrors > 0 &&
      notify(
        "Todavía no llenas la información necesaria en la compañía para continuar",
        "error"
      );

    if (internErrors + insuranceErrors + companyErrors > 0) {
      return true;
    }
    return false;
  };

  const verifyErrors = async (contract, label) => {
    if (
      (contract.contractStatus == "Activo" || contract.contractApprovalDate) &&
      label == "Activo"
    ) {
      if (!contract.isSifeDocument && requireSife) {
        notify("Documentos enviados a firma", "success");
        requireSife && (await sendDocs(contract));
        return;
      } else {
        notify("Este convenio ya esta aprobado", "error");
        return;
      }
    }

    if (
      (contract.contractStatus == "Invalidado" ||
        contract.contractStatus == "Inactivo") &&
      label == "Activo"
    ) {
      notify("Este convenio no puede ser aprobado", "error");
      return;
    }

    if (
      (contract.contractStatus == "Invalidado" ||
        contract.contractStatus == "Inactivo") &&
      (label == "Inactivo" || label == "Invalidado")
    ) {
      notify("Esta acción no esta disponible para este convenio", "error");
      return;
    }

    if (contract.contractStatus == "En proceso" && label == "Inactivo") {
      notify(
        "No puedes dar de baja un convenio si aún no esta activo",
        "error"
      );
      return;
    }

    if (label == "Invalidado" && getLastContract(contract)) {
      notify("No puedes invalidar este convenio", "error");
      return;
    }

    let momentStart = contract.internStartDate.includes("/")
      ? moment(contract.internStartDate, "DD/MM/YYYY").startOf("day")
      : moment(contract.internStartDate.slice(0, 10)).startOf("day");

    if (momentStart.diff(moment(), "days") > 30) {
      notify(
        "El convenio no puede ser aprobado hasta 30 días antes de la fecha de inicio.",
        "error"
      );
      return;
    }

    if (
      contract.requireInsurance &&
      label != "Invalidado" &&
      label != "Inactivo"
    ) {
      const sendData = await getInsuranceDataToSend(
        currentInsuranceData,
        currentInternData,
        currentCompany
      );
      if (verifyDataToSend(sendData)) {
        return;
      }
    }

    setShowUpdateContract(true);
    setContratToUpdate(contract);
    setContractActionLabel(label);
  };

  const getLastContract = (contractToEvaluate) => {
    let startDate = moment(
      getFormatedDate(contractToEvaluate.internStartDate)
    ).startOf("day");
    for (let contract of internContracts) {
      let contractEndDate = contract.unsuscribeDate
        ? moment(contract.unsuscribeDate).startOf("day")
        : moment(getFormatedDate(contract.internEndDate)).startOf("day");
      if (
        contract.contractStatus != "Invalidado" &&
        startDate.isAfter(contractEndDate) &&
        contract._id != contractToEvaluate._id
      )
        return false;
    }
    return true;
  };

  const canViewData = (contract) => {
    if (
      contract._id == currentContractId ||
      contract.contractStatus == "Inactivo" ||
      contract.contractApprovalDate
    )
      return false;
    return true;
  };

  const approveConvenioHandler = (contract) => {
    if (!contract.internStartDate) {
      verifyErrors(selectedApproveConvenio, "Activo");
    } else {
      verifyErrors(contract, "Activo");
    }
    setShowApproveConvenio(false);
  };

  const renderInternContracts = () => {
    let internContractsFiltered = [];
    let Activos =
      internContracts &&
      internContracts.filter((intern) => intern.contractStatus == "Activo");
    let Inactivos =
      internContracts &&
      internContracts.filter((intern) => intern.contractStatus == "Inactivo");
    let inProcess =
      internContracts &&
      internContracts.filter((intern) => intern.contractStatus == "En proceso");
    let PendingContracts =
      internContracts &&
      internContracts.filter((intern) => intern.contractStatus == "Pendiente");

    Activos && internContractsFiltered.push(...Activos);
    PendingContracts && internContractsFiltered.push(...PendingContracts);
    inProcess && internContractsFiltered.push(...inProcess);
    Inactivos && internContractsFiltered.push(...Inactivos);
    return (
      <div className="table-container">
        <Table hover>
          <thead>
            <tr>
              <th>Fecha de Inicio</th>
              <th>Fecha de Término</th>
              <th>Fecha de Aprobación</th>
              <th>Fecha de Baja</th>
              <th>Estatus</th>
              <th>Acciones</th>
            </tr>
          </thead>

          <tbody>
            {internContractsFiltered ? (
              internContractsFiltered.map((contract, index) => {
                return (
                  <tr key={index}>
                    <td className="center-text">{contract.internStartDate}</td>
                    <td className="center-text">{contract.internEndDate}</td>
                    <td className="center-text">
                      {contract.contractApprovalDate
                        ? moment(contract.contractApprovalDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </td>
                    <td className="center-text">
                      {contract.unsuscribeDate
                        ? moment(
                            contract.unsuscribeDate.substring(0, 10)
                          ).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td
                      className="center-text"
                      style={{
                        color: getColorByStatus(contract.contractStatus),
                      }}
                    >
                      {contract.contractStatus || ""}
                    </td>
                    <td className="center-text">
                      {canViewData(contract) && (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="button-tooltip-2">
                              Editar Convenio
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="on-click-event"
                            icon={faEdit}
                            onClick={async () => updateContract(contract)}
                            style={{ fontSize: "20px" }}
                          />
                        </OverlayTrigger>
                      )}
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Descargar Convenio
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className="on-click-event"
                          onClick={async () => downloadContract(contract)}
                          icon={faEye}
                          style={{ fontSize: "20px" }}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Aprobar Convenio
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className="on-click-event"
                          onClick={async () => {
                            setSelectedApproveConvenio(contract);
                            if (
                              Activos.length === 0 &&
                              Inactivos.length === 0
                            ) {
                              sendEmail(currentInternData);
                            }
                            contract.requireInsurance
                              ? setShowApproveConvenio(true)
                              : approveConvenioHandler(contract);
                          }}
                          icon={faCheckCircle}
                          style={{ fontSize: "20px", color: "#06D1CD" }}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Dar de Baja Convenio
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className="on-click-event"
                          onClick={async () => {
                            verifyErrors(contract, "Inactivo");
                          }}
                          icon={faTimesCircle}
                          style={{ fontSize: "20px", color: "#FFA825" }}
                        />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Invalidar Convenio
                          </Tooltip>
                        }
                      >
                        <FontAwesomeIcon
                          className="on-click-event"
                          onClick={async () => {
                            verifyErrors(contract, "Invalidado");
                          }}
                          icon={faTrashAlt}
                          style={{ fontSize: "20px", color: "#FA0000" }}
                        />
                      </OverlayTrigger>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  const handleChangeProfilePhoto = async (internId, photo) => {
    if (!canEditIntern()) {
      notify(
        "No tienes acceso a esta funcionalidad, solicítalo a un administrador",
        "error"
      );
      return;
    }

    try {
      const fileExtension = getExtension(photo.name);
      const fileName = `picture_${internId}.${fileExtension}`;
      const extensions = ["png", "jpg", "jpeg"];
      const path = `interns/docs/${internId}/${fileName}`;

      if (!extensions.includes(fileExtension)) {
        notify(
          "¡La foto de perfil debe estar en formato PNG, JPG o JPEG!",
          "error"
        );
        return;
      }

      if (photo.size > 10485760) {
        notify(`El archivo ${photo.name} pesa más de 10 MB`, "error");
        return;
      }

      let data = {
        path: path,
        fileName: fileName,
        fileKey: photo.key,
        fileExtension: fileExtension,
      };

      await Methods.getPresignedUrlAndPutImage(data, photo);
      await Methods.updateProfilePictureUrl(path, internId);

      setUpdateInfo(!updateInfo);
      notify("¡Foto de Perfil cambiada con éxito!", "success");
    } catch (e) {
      e.response && notify(e.response.data.message, "error");
    }
  };

  const changeVerifyTab = (tabName) => {
    setNewTabName(tabName);
    if (formChanged) {
      setShowVerifyChangeTab(!showVerifyChangeTab);
    } else if (documentationTab != tabName) {
      setDocumentationTab(tabName);
      if (tabName == "internDocs") setUpdateInfo(!updateInfo);
    }
  };

  const setMainBank = (selectedBank) => {
    if (
      selectedBank == "Banorte" ||
      selectedBank == "Bancomer" ||
      selectedBank == "Santander"
    ) {
      setIsMainBank(true);
    } else {
      setIsMainBank(false);
    }
  };

  const canEditIntern = () => {
    if (currentInternData.internStatus == "Activo") {
      return canEdit;
    }
    return canEdit;
  };

  const userCan = (section, action) => {
    if (!context.can(action, section)) {
      notify("No tienes acceso a esta funcionalidad", "error");
      return false;
    } else {
      return true;
    }
  };

  const sendEmail = async () => {
    const urlStartProcess = `${window.location.origin}/internship/${currentInternData.surveyKey}`;

    const emailParams = {
      to: currentInternData.email,
      subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
      text: `Hola ${currentInternData.firstname} ${currentInternData.father_lastname} ${currentInternData.mother_lastname}! <br/> 

            Te confirmamos que tu convenio de prácticas se encuentra aprobado.
            <br/>
            <strong>Te invitamos a ingresar a nuestra plataforma <span style="color: #0080fd;">grow</span></strong> exclusiva para ti, en ella podrás encontrar:
            <br>

            <img src="https://caintrabucket-prod.s3.amazonaws.com/mailImages/b1.png" style="width:20px;margin-right:10px; margin-top:30px"/> Descargar tu convenio y póliza de seguro en cualquier momento.
            <br>
            <img src="https://caintrabucket-prod.s3.amazonaws.com/mailImages/b2.png" style="width:20px;margin-right:10px; margin-top:30px"/>Ingresar a cursos de capacitación.
            <br>
            <img src="https://caintrabucket-prod.s3.amazonaws.com/mailImages/b3.png" style="width:20px;margin-right:10px; margin-top:30px"/>Obtener insignias y descargar diplomas con los que puedes enriquecer tu currículum.
            <br>
            <img src="https://caintrabucket-prod.s3.amazonaws.com/mailImages/b4.png" style="width:20px;margin-right:10px; margin-top:30px"/>Armar tu expediente digital.
            
          
            <br>
            <br>
            <br>
            <strong>¡Ingresa ahora! </strong>

            Link Web: CAINTRA(growpracticantes.com)
            <br>
            Consulta el archivo adjunto que te será de ayuda para ingresar a la nueva plataforma grow.

            <br>

            <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/Manual.pdf">Manual</a>
            <br/>
            En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
            <br/>
            <span style="color: #0080fd;"><a href="mailto:soporte.practicantes@caintra.org.mx">soporte.practicantes@caintra.org.mx</a></span>
            <br>
            Para gestionar tus preferencias de comunicación y dar de baja tu correo electrónico de nuestra base de datos de envío de avisos, por favor responde a este mensaje con la palabra "BAJA" al correo soporte.practicantes@caintra.org.mx Nos aseguraremos de procesar tu solicitud a la brevedad posible.
           
            `,
    };

    /*  const emailParams = {
            to: currentInternData.email,
            subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
            text: `¡Bienvenido ${currentInternData.firstname} ${currentInternData.father_lastname} ${currentInternData.mother_lastname}! <br/> 

            Estás a punto de iniciar el primer paso hacía tu desarrollo profesional.
            <br/>

            <span style="font-weight: bold;">¡Ingresa ahora y contesta el formulario de prácticas!</span>
            <br/>

            Link Web: <a href="${urlStartProcess}">${urlStartProcess}</a>
            <br/>
            Consulta el archivo adjunto que te será de ayuda para conocer los beneficios de <span style="color: #0080fd;">Practicantes CAINTRA</span>
            <br/>

            <a href="https://caintrabucket-prod.s3.amazonaws.com/mailImages/ToolKit.pdf"></a>
            <br/>
            En caso de presentar problemas para acceder, por favor notifícalo al correo de atención:
            <br/>
            <span style="color: #0080fd;"><a href="mailto:practicantes@caintra.org.mx">practicantes@caintra.org.mx</a></span>


            <br/><br/> En caso de presentar problemas para accesar por favor notificalo mediante el correo  de atención: <a href="mailto:practicantes@caintra.org.mx">practicantes@caintra.org.mx</a>
            `,
         
        }; */
    /*       const emailParams = {
            to: currentInternData.email,
            subject: `Inicia tu proceso de inscripción al Programa de Prácticas con ${context.user.company.business_name}`,
            text: `Estás a punto de iniciar el primer paso hacia tu desarrollo profesional.
            <br/><br/> Por favor ingresa a este link para comenzar tu proceso: <a href="${urlStartProcess}">${urlStartProcess}</a>
            <br/><br/> En caso de presentar problemas para accesar por favor notificalo mediante el correo  de atención: <a href="mailto:practicantes@caintra.org.mx">practicantes@caintra.org.mx</a>
            ${requireSife ? `<br/><br/> Tambien crea una cuenta en <a href="${sifeEmailUrl()}">${sifeEmailUrl()}</a> para poder firmar tus documentos posteriormente` : ""}`,
            internName: currentInternData.firstname
        }; */

    await Methods.sendExternalEmail(currentInternData, emailParams);
  };
  const deleteUnsigned = async (contractId) => {
    const body = {
      id: contractId,
    };
    try {
      await axios({
        method: "DELETE",
        url: `${URL}/delete/sife`,
        data: body,
      });
      return true;
    } catch (e) {
      return false;
    }
  };

  const sendDocs = async (contractToEvaluate) => {
    let universityData = contractToEvaluate.collegeContact;
    let data = {
      name:
        contractToEvaluate.internFirstName +
        " " +
        contractToEvaluate.internFatherLastname +
        " " +
        contractToEvaluate.internMotherLastname,
      email: contractToEvaluate.internEmail,
    };
    let internDataToUpdate = {
      _id: currentInternId,
      attributes: [
        { updateKey: "signatureStatus", updateValue: "Pendiente" },
        {
          updateKey: "lastSignatureReminder",
          updateValue: moment().format("DD/MM/YYYY"),
        },
      ],
    };

    let promises = [];
    let sendToUniversity = true;
    let universityLogo = "";
    let signatureUniversity = "";
    let signatureIntern = "";
    let docName =
      rfc +
      " " +
      (isITESMCompany ? contractToEvaluate.schoolEnrollment : "") +
      " " +
      contractToEvaluate.internStartDate +
      " " +
      contractToEvaluate.internEndDate +
      " " +
      moment().format("HH:mm");
    let [company] = await Methods.getCompanies([contractToEvaluate.companyId]);

    if (
      contractToEvaluate.collegeName == "" ||
      contractToEvaluate.collegeName == "Otro"
    ) {
      notify(
        "Se necesita seleccionar una universidad para la creación del convenio",
        "error"
      );
    }

    if (!universityData.email) {
      sendToUniversity = false;
      if (universityData?.logo) {
        notify(
          "La universidad no cuenta con contacto ni con logo para la creación y envío del convenio para firma electroníca",
          "error"
        );
      }
    }

    if (!universityData.email) {
      sendToUniversity = false;
      if (!universityLogo) {
        notify(
          "La universidad no cuenta con contacto ni con logo para la creación y envío del convenio para firma electroníca",
          "error"
        );
      }
    }

    try {
      let blob = await createSignatureDocument(
        contractToEvaluate,
        signatureIntern,
        universityLogo,
        signatureUniversity,
        company.logo_url
      );

      if (blob) {
        let phone =
          currentInternData.phone || currentInternData.internPhone || "";
        let phoneLada = "52" + phone;
        let phoneNumber = parseInt(phoneLada);

        let signers = [
          {
            name: currentInternData.firstname || " ",
            lastNameP: currentInternData.father_lastname || " ",
            lastNameM: currentInternData.mother_lastname || " ",
            email: currentInternData.email || currentInternData.internEmail,
            phone: phoneNumber,
            rfc: "",
          },
        ];
        if (sendToUniversity) {
          phone = universityData.phone || universityData.internPhone || "";
          phoneLada = "52" + phone;
          phoneNumber = parseInt(phoneLada);
          signers.push({
            name: universityData.firstname || " ",
            lastNameP: universityData.father_lastname || " ",
            lastNameM: universityData.mother_lastname || " ",
            email: universityData.email || universityData.internEmail,
            phone: phoneNumber,
            rfc: "",
          });
        }
        if (context.user.company.org_admin_email) {
          phone = context.user.company.org_admin_phone || "";
          phoneLada = "52" + phone;
          phoneNumber = parseInt(phoneLada);
          signers.push({
            name: context.user.company.org_admin_fullname || " ",
            lastNameP: context.user.company.org_admin_fullname || " ",
            lastNameM: context.user.company.org_admin_fullname || " ",
            email: context.user.company.org_admin_email,
            phone: phoneNumber,
            rfc: "",
          });
        }
        promises.push(
          sendSignature(
            currentInternData,
            blob,
            contractToEvaluate._id,
            false,
            docName,
            signers
          )
        );
        promises.push(Methods.notifyIntern(data));
        promises.push(Methods.updateIntern(internDataToUpdate));
      }
      await Promise.all(promises);
    } catch (e) {
      notify("Ocurrio un error al generar el convenio");
    }
  };

  const updateContract = async (contract) => {
    setContractToUpdateRenew(contract);
    setCurrentTab("editContrat");
  };

  const setFormValueAndUpdateBar = (dataName, dataValue) => {
    currentInternData[dataName] = dataValue;
    if (dataValue) setCurrentInternData({ ...currentInternData });
    setFormChanged(true);
    setReloadProgressBar(!reloadProgressBar);
  };

  return (
    <motion.div
      initial={{ x: 30, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.25 }}
      className="verify-interns-docs"
    >
      <Row
        style={{ display: "flex", flexDirection: "row", marginBottom: "15px" }}
      >
        <a
          href="#"
          onClick={() => {
            if (updatedFromPrefacture) {
              setCurrentTab("prefactureList");
              setUpdateList(!updateList);
            } else {
              setCurrentTab("internList");
            }
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
        <div>
          <h4>Verificación de Datos</h4>
          <h3 className="company">{currentCompanyName}</h3>
        </div>
      </Row>

      <Row className="row-selection">
        <a
          href="#"
          onClick={(e) => {
            changeVerifyTab("internDocs");
          }}
        >
          <p className={`${documentationTab == "internDocs" && "selected"}`}>
            Datos Principales
          </p>
        </a>

        <a>
          <p style={{ color: "lightgray" }}>|</p>
        </a>

        {currentInternData.requireInsurance && (
          <a
            href="#"
            onClick={(e) => {
              changeVerifyTab("internMedicalInsurance");
            }}
          >
            <p
              className={`${
                documentationTab == "internMedicalInsurance" && "selected"
              }`}
            >
              Seguro
            </p>
          </a>
        )}
      </Row>

      {documentationTab == "internDocs" && (
        <div style={{ marginTop: "20px" }}>
          <h6>
            <strong>- Porcentaje de avance de formulario</strong>
          </h6>
          <Row>
            <Col>
              <ProgressBar
                requiredKeys={requiredKeysProgressbar}
                data={currentInternData}
                isChanged={reloadProgressBar}
              />
            </Col>
          </Row>
        </div>
      )}

      {documentationTab == "internDocs" && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Row className="form-overflow">
            <div className="w-100 d-flex flex-column">
              <div
                className="profile-picture"
                style={{
                  backgroundImage:
                    currentInternData.profile_picture != ""
                      ? `url("${currentInternData.profile_picture}")`
                      : `url("${DefaultUser}")`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              >
                <a href="#" className="profile-photo-camera">
                  <Form.Group className="files">
                    <a href="#">
                      <Form.File
                        onChange={(e) => {
                          e.preventDefault();
                          if (e.target.files[0])
                            handleChangeProfilePhoto(
                              currentInternData._id,
                              e.target.files[0]
                            );
                        }}
                        id={currentInternData._id}
                        label={
                          <FontAwesomeIcon
                            icon={faCamera}
                            style={{ fontSize: "28px" }}
                          />
                        }
                      />
                    </a>
                  </Form.Group>
                </a>
              </div>
            </div>
            <Form
              style={{ width: "100%", margin: "0px 10px 10px 10px" }}
              disabled={!canEditIntern()}
              onSubmit={updateIntern}
              id="updateInternForm"
            >
              <h6>
                <strong>- Información general</strong>
              </h6>
              <Row>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="firstname">
                    <Form.Label>Nombre(s)</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.firstname}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        setFormValueAndUpdateBar(
                          "firstname",
                          e.target.value.toUpperCase()
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="email">
                    <Form.Label>Correo</Form.Label>
                    <Form.Control
                      readOnly={!isCaintraUser}
                      type="text"
                      onChange={(e) => {
                        setFormValueAndUpdateBar("email", e.target.value);
                        updateKey("email", e.target.value);
                        verifyDataForm(
                          "is_email_error",
                          "Email",
                          formValidate.email,
                          setIsEmailError
                        );
                      }}
                      defaultValue={currentInternData.email}
                    />
                    {isEmailError && (
                      <Form.Label className="error-label">
                        {" "}
                        {formValidate.label_error_email}
                      </Form.Label>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <div className="add-deparment-containter">
                      <p
                        className="department-text"
                        onClick={() => {
                          userCan("Departments", "create") &&
                            setShowNewDepartmentModal(!showNewDepartmentModal);
                        }}
                      >
                        {" "}
                        Añadir {context.getDepartmentField()}{" "}
                        <img src={plusIconSvg} size={25} />
                      </p>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="department_id">
                    <Form.Label>
                      {context.getDepartmentField(true)}{" "}
                      {currentInternData.department_id == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      disabled={!canEditIntern()}
                      readOnly={!canEditIntern()}
                      as="select"
                      onChange={async (e) => {
                        setCurrentDepartmentId(e.target.value);
                        await setLeader(e.target.value);
                      }}
                    >
                      {!isLoading && (
                        <option
                          disabled
                          selected
                          hidden
                          id={currentInternData.department_name}
                          value={currentDepartmentId}
                        >
                          {currentInternData.department_name || ""}
                        </option>
                      )}
                      {!isLoading && departments.length > 0 ? (
                        departments.map((department, index) => {
                          if (department) {
                            return (
                              <option
                                key={index}
                                id={department.title}
                                value={department._id}
                              >
                                {department.title}
                              </option>
                            );
                          }
                        })
                      ) : (
                        <option disabled>
                          No hay {context.getDepartmentField(false, true)}
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="projectName">
                    <Form.Label>
                      Proyecto{" "}
                      {currentInternData.projectName == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.projectName}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("projectName", e.target.value)
                      }
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="father_lastname">
                    <Form.Label>Apellido Paterno</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.father_lastname}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        setFormValueAndUpdateBar(
                          "father_lastname",
                          e.target.value
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="scolarshipAmount">
                    <Form.Label>
                      Monto de la Beca{" "}
                      {currentInternData.scolarshipAmount == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <InputGroup className="mb-2">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        readOnly={!canEditIntern()}
                        type="number"
                        step=".01"
                        defaultValue={currentInternData.scolarshipAmount}
                        onChange={(e) =>
                          setFormValueAndUpdateBar(
                            "scolarshipAmount",
                            e.target.value
                          )
                        }
                        onWheel={(e) => e.target.blur()}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <div className="add-deparment-containter">
                      <p
                        className="department-text"
                        onClick={() => {
                          userCan("Departments", "create") &&
                            setShowNewCostCenterModal(true);
                        }}
                      >
                        Añadir Centro de Costos{" "}
                        <img src={plusIconSvg} size={25} />
                      </p>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="cost_center_name">
                    <Form.Label>Centro de Costos</Form.Label>
                    <Form.Control
                      disabled={!canEditIntern()}
                      readOnly={!canEditIntern()}
                      as="select"
                      onChange={(e) =>
                        setFormValueAndUpdateBar(
                          "cost_center_name",
                          e.target.value
                        )
                      }
                    >
                      <option disabled selected hidden>
                        {currentInternData.cost_center_name || ""}
                      </option>
                      {context.user.company.isITESMCompany == true ? (
                        costCentersTec && costCentersTec.length > 0 ? (
                          costCentersTec.map((k) => (
                            <option key={k._id} id={k.name} value={k._id}>
                              {k.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>No hay centro de costos</option>
                        )
                      ) : (
                        departments.map((department) => {
                          if (
                            department._id ===
                            (currentDepartmentId ||
                              currentInternData.department_id)
                          ) {
                            if (department.costCenters.length > 0) {
                              return department.costCenters.map(
                                (costCenter, index) => {
                                  if (costCenter) {
                                    return (
                                      <option
                                        key={index}
                                        id={costCenter.title}
                                        value={costCenter._id}
                                      >
                                        {costCenter.title}
                                      </option>
                                    );
                                  }
                                }
                              );
                            } else {
                              return (
                                <option disabled>
                                  No hay centro de costos
                                </option>
                              );
                            }
                          }
                        })
                      )}

                      {!currentDepartmentId &&
                        !currentInternData.department_name && (
                          <option disabled>
                            Aun no seleccionas un {context.getDepartmentField()}
                          </option>
                        )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="entry_time">
                    <Form.Label style={{ width: "100%" }}>
                      Hora de entrada{" "}
                      {currentInternData.entry_time == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    {entryTime && (
                      <TimePicker
                        onChange={onChangeEntry}
                        readOnly={!canEditIntern()}
                        use12Hours
                        format="h:mm a"
                        defaultValue={moment(entryTime, "h:mm a")}
                        className={"time-picker"}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="mother_lastname">
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.mother_lastname}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        setFormValueAndUpdateBar(
                          "mother_lastname",
                          e.target.value.toUpperCase()
                        );
                      }}
                    />
                  </Form.Group>

                  <Form.Group controlId="internStatus">
                    <Form.Label>Estatus</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      value={currentInternData.internStatus}
                      onChange={(e) => setFormChanged(true)}
                    />
                  </Form.Group>

                  <Form.Group>
                    <div className="add-deparment-containter">
                      <p
                        className="department-text"
                        onClick={() => {
                          userCan("Users", "create") &&
                            setShowCreateUserModal(true);
                        }}
                      >
                        Añadir {context.getGeneralistaField()}
                        <img src={plusIconSvg} size={25} />
                      </p>
                    </div>
                  </Form.Group>

                  <Form.Group controlId="directBoss_name">
                    <Form.Label>
                      {context.getGeneralistaField(true)}{" "}
                      {currentInternData.directBoss_name == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      onChange={(e) => {
                        setNewLeader(e.target.value);
                      }}
                    >
                      {context.user.company.isITESMCompany == true ? (
                        generalistasTec && generalistasTec.length > 0 ? (
                          generalistasTec.map((k) => (
                            <option
                              key={k._id}
                              id={k._id}
                              value={k._id}
                              selected={k.name == currentLeaderName}
                            >
                              {k.name}
                            </option>
                          ))
                        ) : (
                          <option disabled>
                            No hay {context.getGeneralistaField(false, true)}
                          </option>
                        )
                      ) : !isLoading && departments.length ? (
                        departments.map((department) => {
                          if (
                            department._id ===
                            (currentDepartmentId ||
                              currentInternData.department_id)
                          ) {
                            return department.leader?.length ? (
                              department.leader.map((leader) => {
                                return (
                                  <option
                                    key={leader._id}
                                    value={leader._id}
                                    selected={
                                      leader.fullName == currentLeaderName
                                    }
                                  >
                                    {leader.fullName}
                                  </option>
                                );
                              })
                            ) : (
                              <option disabled>
                                No hay{" "}
                                {context.getGeneralistaField(false, true)}
                              </option>
                            );
                          }
                        })
                      ) : (
                        <option disabled>
                          No hay {context.getGeneralistaField(false, true)}
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="depurate_time">
                    <Form.Label style={{ width: "100%" }}>
                      Hora de salida{" "}
                      {currentInternData.depurate_time == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    {depurateTime && (
                      <TimePicker
                        onChange={onChangeDepurate}
                        readOnly={!canEditIntern()}
                        use12Hours
                        format="h:mm a"
                        defaultValue={moment(depurateTime, "h:mm a")}
                        className={"time-picker"}
                      />
                    )}
                  </Form.Group>
                </Col>

                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="contractstartdate">
                    <Form.Label>Inicio del convenio</Form.Label>
                    <DateTimePicker
                      name="contractstartdate"
                      editFormat={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      onCurrentDateChange={(date) => {
                        handleNewDate(date, "start");
                      }}
                      time={false}
                      value={contractStartDate}
                      disabled={canEditDate}
                    />
                  </Form.Group>

                  <Form.Group controlId="contractenddate">
                    <Form.Label>Término de convenio</Form.Label>
                    <DateTimePicker
                      name="contractendate"
                      editFormat={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      onCurrentDateChange={(date) => {
                        handleNewDate(date, "end");
                      }}
                      time={false}
                      value={contractEndDate}
                      disabled={canEditDate}
                    />
                  </Form.Group>

                  <Form.Group>
                    <p style={{ visibility: "hidden", fontSize: "12px" }}>
                      {" "}
                      Añadir {context.getGeneralistaField()}
                    </p>
                  </Form.Group>

                  <Form.Group controlId="directBoss_email">
                    <Form.Label>
                      Correo de {context.getGeneralistaField()}
                      {currentInternData.directBoss_email == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="email"
                      autoComplete="none"
                      disabled
                      onChange={(e) => {
                        updateKey("email_leader", e.target.value);
                        verifyDataForm(
                          "is_email_leader_error",
                          "Email",
                          formValidate.email_leader,
                          setIsEmailLeaderError
                        );
                        setFormValueAndUpdateBar(
                          "directBoss_email",
                          e.target.value
                        );
                      }}
                      defaultValue={currentLeaderEmail}
                    />
                    {isEmailLeaderError && (
                      <Form.Label className="error-label">
                        {" "}
                        {formValidate.label_error_email}
                      </Form.Label>
                    )}
                  </Form.Group>

                  {context.user.company.isITESMCompany && (
                    <Form.Group controlId="leaderTec">
                      <Form.Label>Líder</Form.Label>
                      <Form.Control
                        readOnly={!canEditIntern()}
                        type="text"
                        defaultValue={currentInternData.leaderTec}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          setFormValueAndUpdateBar("leaderTec", e.target.value);
                        }}
                      />
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <h6>
                <strong>- Información proporcionada por el practicante</strong>
              </h6>

              <Row>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="street">
                    <Form.Label>
                      Calle{" "}
                      {currentInternData.street == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.street}
                      onChange={(e) => {
                        setFormValueAndUpdateBar("street", e.target.value);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="ext_number">
                    <Form.Label>
                      No. Exterior{" "}
                      {currentInternData.ext_number == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.ext_number}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("ext_number", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="int_number">
                    <Form.Label>No. Interior</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.int_number}
                      onChange={(e) => setFormChanged(true)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="zip_code">
                    <Form.Label>
                      Código Postal{" "}
                      {currentInternData.zip_code == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      onInput={async (e) => {
                        e.target.value = (0, e.target.value)
                          .toString()
                          .slice(0, 5);
                        if (e.target.value.length == 5) {
                          await getDataByZipCode(e.target.value);
                          setFormValueAndUpdateBar("zip_code", e.target.value);
                        }
                      }}
                      defaultValue={currentInternData.zip_code}
                      onChange={(e) => setFormChanged(true)}
                    />
                  </Form.Group>

                  {requireInsurance && (
                    <Form.Group controlId="insurance">
                      <Form.Label>Número de póliza</Form.Label>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Este dato se genera automáticamente
                          </Tooltip>
                        }
                      >
                        <Form.Control
                          readOnly={context.user.data.isCaintraUser || true}
                          type="text"
                          defaultValue={currentInternData.insurance}
                          onChange={(e) => setFormChanged(true)}
                        />
                      </OverlayTrigger>
                    </Form.Group>
                  )}
                </Col>

                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="suburb">
                    <Form.Label>
                      Colonia{" "}
                      {currentInternData.suburb == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      disabled={!canEditIntern()}
                      readOnly={true}
                      as="select"
                      onClick={() =>
                        currentInternData.zip_code &&
                        !currentSuburbs.length &&
                        getDataByZipCode(currentInternData.zip_code)
                      }
                      onChange={(e) =>
                        setFormValueAndUpdateBar("suburb", e.target.value)
                      }
                    >
                      <option disabled selected hidden>
                        {currentInternData.suburb || ""}
                      </option>
                      {currentSuburbs.map((element) => {
                        return <option>{element}</option>;
                      })}
                      {!currentInternData.suburb && !currentSuburbs.length && (
                        <option disabled>
                          Se requiere un código postal valido
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="age">
                    <Form.Label>
                      Edad{" "}
                      {currentInternData.age == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="number"
                      value={internAge}
                      defaultValue={currentInternData.age}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("age", e.target.value)
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>

                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="municipality">
                    <Form.Label>
                      Municipio{" "}
                      {currentInternData.municipality == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentMunicipality}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("municipality", e.target.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group controlId="birthday">
                    <Form.Label>Fecha de nacimiento</Form.Label>
                    <DateTimePicker
                      name="birthday"
                      editFormat={"DD/MM/YYYY"}
                      format={"DD/MM/YYYY"}
                      onChange={(date) => {
                        setInternDataAux({
                          ...internDataAux,
                          birthDate: date,
                          internAge: moment().diff(date, "years"),
                        });
                        currentInternData.age = moment().diff(date, "years");
                        setFormValueAndUpdateBar("birthday", date);
                      }}
                      onCurrentDateChange={(date) => {
                        setInternDataAux({
                          ...internDataAux,
                          birthDate: date,
                          internAge: moment().diff(date, "years"),
                        });
                        currentInternData.age = moment().diff(date, "years");
                        setFormValueAndUpdateBar("birthday", date);
                      }}
                      time={false}
                      value={birthDate}
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="_state">
                    <Form.Label>
                      Estado{" "}
                      {currentInternData._state == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentState}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("_state", e.target.value)
                      }
                    />
                  </Form.Group>
                  <Form.Group controlId="phone">
                    <Form.Label>
                      Teléfono{" "}
                      {currentInternData.phone == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="number"
                      autoComplete="none"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      onChange={(e) => {
                        setFormValueAndUpdateBar("phone", e.target.value);
                        verifyDataForm(
                          "is_phone_error",
                          "Phone",
                          formValidate.phone,
                          setIsPhoneError
                        );
                      }}
                      defaultValue={currentInternData.phone}
                    />
                    {isPhoneError && (
                      <Form.Label className="error-label">
                        {" "}
                        {formValidate.label_error_phone}
                      </Form.Label>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="curp">
                    <Form.Label>
                      CURP{" "}
                      {(currentInternData.curp == "" ||
                        currentInternData.curp == "_") && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.curp}
                      onChange={(e) => {
                        currentInternData.curp = e.target.value;
                        curpValidation(currentInternData.curp);
                        setFormValueAndUpdateBar("curp", e.target.value);
                      }}
                    />
                    {isCurpError && (
                      <Form.Label className="error-label">
                        {" "}
                        {curpErrorLabel}
                      </Form.Label>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="rfc">
                    <Form.Label>
                      RFC{" "}
                      {rfc == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      onInput={(e) => {
                        setInternDataAux({
                          ...internDataAux,
                          rfc: e.target.value.substring(0, 13),
                        });
                      }}
                      value={rfc}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("rfc", e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="gender">
                    <Form.Label>
                      Género{" "}
                      {gender == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      value={gender}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("gender", e.target.value)
                      }
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="college">
                    <Form.Label>
                      Universidad{" "}
                      {currentInternData.college == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    {!universities.length ? (
                      <div className="center-container">
                        <Spinner animation="border" role="status" />
                      </div>
                    ) : (
                      <Form.Control
                        readOnly={!canEditIntern()}
                        disabled={!canEditIntern()}
                        as="select"
                        onChange={(e) => {
                          setUniversitySelected(e.target.value);
                          currentInternData.faculty =
                            e.target.value == "Otro" ? "Otro" : "";
                          currentInternData.career =
                            e.target.value == "Otro" ? "Otro" : "";
                          currentInternData.college = e.target.value;
                          setFormChanged(true);
                        }}
                      >
                        <option disabled selected hidden>
                          {currentInternData.college || ""}
                        </option>
                        {universities.length > 0 ? (
                          universities.map((university, index) => {
                            if (!university.isHistorical) {
                              return (
                                <option
                                  key={index}
                                  id={university._id}
                                  value={university._name}
                                >
                                  {university._name}
                                </option>
                              );
                            }
                          })
                        ) : (
                          <option disabled>No hay universidades</option>
                        )}
                        <option value="Otro">Otro</option>
                      </Form.Control>
                    )}
                  </Form.Group>

                  <Form.Group controlId="school_enrollment">
                    <Form.Label>
                      Matrícula{" "}
                      {currentInternData.school_enrollment == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData.school_enrollment}
                      onChange={(e) =>
                        setFormValueAndUpdateBar(
                          "school_enrollment",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>

                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Una vez aprobado, este campo solo podrá ser cambiado por
                        un asesor
                      </Tooltip>
                    }
                  >
                    <Form.Group controlId="bank">
                      <Form.Label>
                        Banco{" "}
                        {(currentInternData.bank == "" ||
                          currentInternData.bank == "Ninguno") && (
                          <FontAwesomeIcon
                            style={{ fontSize: "15px", margin: 0, padding: 0 }}
                            color="orange"
                            icon={faExclamationTriangle}
                          />
                        )}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        disabled
                        onChange={(e) => {
                          setMainBank(e.target.value);
                          currentInternData.bank = e.target.value;
                          setFormChanged(true);
                        }}
                      >
                        <option disabled selected hidden>
                          {currentInternData.bank || ""}
                        </option>
                        <option>Ninguno</option>
                        <option>Banorte</option>
                        <option>Bancomer</option>
                        <option>Santander</option>
                        <option>Banamex</option>
                        <option>HSBC</option>
                        <option>Afirme</option>
                        <option>Banjio</option>
                        <option>Banjercito</option>
                        <option>Bancoppel</option>
                        <option>Inbursa</option>
                        <option>Scotiabank</option>
                        <option>Banco Azteca</option>
                        <option>Banregio</option>
                        <option>Bancomext</option>
                      </Form.Control>
                    </Form.Group>
                  </OverlayTrigger>

                  <Form.Group controlId="linkedin">
                    <Form.Label>LinkedIn</Form.Label>
                    <Row>
                      <Col sm={1}>
                        <FontAwesomeIcon
                          icon={faLinkedinIn}
                          style={{ fontSize: "25px" }}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly={!canEditIntern()}
                          type="text"
                          defaultValue={currentInternData.linkedin}
                          onChange={(e) => setFormChanged(true)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="faculty">
                    <Form.Label>
                      {context.getFacultadField(true)}{" "}
                      {currentInternData.faculty == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      disabled={!canEditIntern()}
                      as="select"
                      onChange={(e) => {
                        const { name, region } = JSON.parse(e.target.value);
                        setFacultySelected(name);
                        currentInternData.faculty = name;
                        currentInternData.career = "";
                        currentInternData.region = region;
                        setFormValueAndUpdateBar("faculty", name);
                        setFormValueAndUpdateBar("region", region);
                        if (region) {
                          setInternDataAux({
                            ...internDataAux,
                            requireRegion: true,
                          });
                        } else {
                          setInternDataAux({
                            ...internDataAux,
                            requireRegion: false,
                          });
                        }
                      }}
                    >
                      <option selected hidden>
                        {currentInternData.faculty || ""}
                      </option>
                      {universities.map((university) => {
                        if (
                          university._name ===
                          (universitySelected || currentInternData.college)
                        ) {
                          if (
                            university.faculties &&
                            university.faculties.length > 0
                          ) {
                            return university.faculties.map(
                              (faculty, index) => {
                                return (
                                  <option
                                    key={index}
                                    id={faculty._name}
                                    value={JSON.stringify({
                                      name: faculty._name,
                                      region: faculty.region,
                                    })}
                                  >
                                    {faculty._name}
                                  </option>
                                );
                              }
                            );
                          } else {
                            return (
                              <option disabled value="">
                                No hay {context.getFacultadField(false, true)}
                              </option>
                            );
                          }
                        }
                      })}
                      {!currentInternData.college && !universitySelected && (
                        <option disabled>
                          Aun no seleccionas una universidad
                        </option>
                      )}
                      {currentInternData.faculty == "Otro" && (
                        <option value="Otro" disabled>
                          No hay {context.getFacultadField(false, true)}
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="grade">
                    <Form.Label>
                      Grado{" "}
                      {currentInternData.grade == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      disabled={!canEditIntern()}
                      readOnly={!canEditIntern()}
                      as="select"
                      onChange={(e) => {
                        setFormValueAndUpdateBar("grade", e.target.value);
                      }}
                    >
                      <option disabled selected hidden>
                        {currentInternData.grade || ""}
                      </option>
                      {gradeArray.map((element) => {
                        return <option>{element}</option>;
                      })}
                    </Form.Control>
                  </Form.Group>
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        Una vez aprobado, este campo solo podrá ser cambiado por
                        un asesor
                      </Tooltip>
                    }
                  >
                    <Form.Group controlId="clabe">
                      <Form.Label>
                        {isMainBank ? "Cuenta" : "CLABE"}
                        {currentInternData.clabe == "" && (
                          <FontAwesomeIcon
                            style={{ fontSize: "15px", margin: 0, padding: 0 }}
                            color="orange"
                          />
                        )}
                      </Form.Label>
                      <Form.Control
                        onInput={(e) => {
                          let maxLength = 10;
                          if (
                            /^(santander)$/i.test(
                              currentInternData.bank.toLowerCase()
                            )
                          )
                            maxLength = 11;
                          else if (
                            !/^(bancomer|banorte)$/i.test(
                              currentInternData.bank.toLowerCase()
                            )
                          )
                            maxLength = 18;
                          e.target.value = e.target.value.slice(0, maxLength);
                        }}
                        onClick={(e) => {
                          notify(
                            "Recuerda ingresar la cuenta bancaria con cuidado para evitar errores. Es importante que verifiques los números y dígitos para dispersar la beca de forma segura y sin contratiempos.",
                            "warning"
                          );
                        }}
                        onChange={(e) => {
                          updateKey("account", e.target.value);
                          verifyDataForm(
                            "is_account_error",
                            "Account",
                            formValidate.account,
                            setIsAccountError,
                            currentInternData.bank
                          );
                          setFormValueAndUpdateBar("clabe", e.target.value);
                        }}
                        type="text"
                        defaultValue={currentInternData.clabe}
                      />
                      {isAccountError && (
                        <Form.Label className="error-label">
                          {formValidate.label_error_account}
                        </Form.Label>
                      )}
                    </Form.Group>
                  </OverlayTrigger>

                  <Form.Group controlId="instagram">
                    <Form.Label>Instagram</Form.Label>
                    <Row>
                      <Col sm={1}>
                        <FontAwesomeIcon
                          icon={faInstagram}
                          style={{ fontSize: "25px" }}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly={!canEditIntern()}
                          type="text"
                          defaultValue={currentInternData.instagram}
                          onChange={(e) => setFormChanged(true)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="career">
                    <Form.Label>
                      Carrera{" "}
                      {currentInternData.career == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      disabled={!canEditIntern()}
                      as="select"
                      onChange={(e) => {
                        currentInternData.career = e.target.value;
                        setFormValueAndUpdateBar("career", e.target.value);
                      }}
                    >
                      <option selected hidden>
                        {currentInternData.career || ""}
                      </option>
                      {universities.map((university) => {
                        if (
                          university._name ===
                          (universitySelected || currentInternData.college)
                        ) {
                          if (
                            university.faculties &&
                            university.faculties.length > 0
                          ) {
                            return university.faculties.map((faculty) => {
                              if (
                                faculty._name ===
                                (facultySelected || currentInternData.faculty)
                              ) {
                                if (
                                  faculty.careers &&
                                  faculty.careers.length > 0
                                ) {
                                  return faculty.careers.map(
                                    (career, index) => {
                                      return (
                                        <option
                                          key={index}
                                          id={career._name}
                                          value={career._name}
                                        >
                                          {career._name}
                                        </option>
                                      );
                                    }
                                  );
                                } else {
                                  return (
                                    <option disabled value="">
                                      No hay carreras
                                    </option>
                                  );
                                }
                              }
                            });
                          }
                        }
                      })}
                      {!currentInternData.faculty && !facultySelected && (
                        <option disabled>
                          Aún no hay selección de {context.getFacultadField()}
                        </option>
                      )}
                      {currentInternData.career == "Otro" && (
                        <option value="Otro" disabled>
                          No hay carreras
                        </option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="scholarship">
                    <Form.Label>
                      Escolaridad{" "}
                      {currentInternData.scholarship == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      disabled={!canEditIntern()}
                      readOnly={true}
                      onChange={(e) => {
                        currentInternData.scholarship = e.target.value;
                        setFormValueAndUpdateBar("scholarship", e.target.value);
                      }}
                    >
                      <option disabled selected hidden>
                        {currentInternData.scholarship || ""}
                      </option>
                      <option>Profesional</option>
                      <option>Preparatoria</option>
                      <option>Carrera Técnica</option>
                      <option>Maestría</option>
                      <option>Doctorado</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="_group">
                    <Form.Label>Grupo/Salón</Form.Label>
                    <Form.Control
                      readOnly={!canEditIntern()}
                      type="text"
                      defaultValue={currentInternData._group}
                      onChange={(e) =>
                        setFormValueAndUpdateBar("_group", e.target.value)
                      }
                    />
                  </Form.Group>

                  {context.user.company.isITESMCompany && (
                    <Form.Group controlId="position">
                      <Form.Label>Posición</Form.Label>
                      <Form.Control
                        readOnly={!canEditIntern()}
                        type="text"
                        defaultValue={currentInternData.position}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          setFormValueAndUpdateBar("position", e.target.value);
                        }}
                      />
                    </Form.Group>
                  )}
                </Col>
                <Col md={3} xs={12} sm={12}>
                  <Form.Group controlId="period_type">
                    <Form.Label>
                      Ciclo Escolar{" "}
                      {currentInternData.period_type == "" && (
                        <FontAwesomeIcon
                          style={{ fontSize: "15px", margin: 0, padding: 0 }}
                          color="orange"
                          icon={faExclamationTriangle}
                        />
                      )}
                    </Form.Label>
                    <Form.Control
                      disabled={!canEditIntern()}
                      as="select"
                      readOnly={true}
                      onChange={(e) => {
                        currentInternData.period_type = e.target.value;
                        setFormValueAndUpdateBar("period_type", e.target.value);
                      }}
                    >
                      <option disabled selected hidden>
                        {currentInternData.period_type || ""}
                      </option>
                      <option>Semestral</option>
                      <option>Tetramestral</option>
                      <option>Trimestral</option>
                      <option>Cuatrimestral</option>
                      <option>Bimestral</option>
                      <option>Mensual</option>
                      <option>Pentamestral</option>
                      <option>Anual</option>
                    </Form.Control>
                  </Form.Group>

                  {context.user.company.isITESMCompany &&
                    internDataAux.requireRegion && (
                      <Form.Group controlId="region">
                        <Form.Label>
                          Región{" "}
                          {currentInternData.region == "" && (
                            <FontAwesomeIcon
                              style={{
                                fontSize: "15px",
                                margin: 0,
                                padding: 0,
                              }}
                              color="orange"
                              icon={faExclamationTriangle}
                            />
                          )}
                        </Form.Label>
                        <Form.Control
                          disabled={!canEditIntern()}
                          as="select"
                          readOnly={true}
                          onChange={(e) => {
                            currentInternData.region = e.target.value;
                            setFormValueAndUpdateBar("region", e.target.value);
                          }}
                        >
                          <option disabled selected hidden>
                            {currentInternData.region || ""}
                          </option>
                          {(currentInternData.college ==
                            "Instituto Tecnológico y de Estudios Superiores de Monterrey" ||
                            currentInternData.college ==
                              "INSTITUTO TECNOLOGICO DE ESTUDIOS SUPERIORES DE MONTERREY") && (
                            <React.Fragment>
                              <option>Online</option>
                              <option>
                                EGADE Business School y Escuela de Gobierno y
                                Transfromación Pública
                              </option>
                              <option>Centro/Sur</option>
                              <option>Ciudad de México</option>
                              <option>Monterrey</option>
                              <option>Occidente</option>
                              <option>
                                Vicepresidencia de PrepaTec y Desarrollo
                                Regional
                              </option>
                            </React.Fragment>
                          )}
                          {currentInternData.college ==
                            "Universidad TecMilenio" ||
                            (currentInternData.college == "TECMILENIO" && (
                              <React.Fragment>
                                <option>Online</option>
                                <option>Centro/Sur</option>
                                <option>Ciudad de México</option>
                                <option>Monterrey</option>
                                <option>Occidente</option>
                              </React.Fragment>
                            ))}
                        </Form.Control>
                      </Form.Group>
                    )}
                  {context.user.company.isITESMCompany && (
                    <Form.Group controlId="nomina">
                      <Form.Label>Nómina</Form.Label>
                      <Form.Control
                        readOnly={!canEditIntern()}
                        type="text"
                        defaultValue={currentInternData.nomina}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          setFormValueAndUpdateBar("nomina", e.target.value);
                        }}
                      />
                    </Form.Group>
                  )}

                  {context.user.company.isITESMCompany && (
                    <Form.Group controlId="program">
                      <Form.Label>
                        Programa{" "}
                        {currentInternData.program == "" && (
                          <FontAwesomeIcon
                            style={{ fontSize: "15px", margin: 0, padding: 0 }}
                            color="orange"
                            icon={faExclamationTriangle}
                          />
                        )}
                      </Form.Label>
                      <Form.Control
                        disabled={!canEditIntern()}
                        as="select"
                        readOnly={true}
                        onChange={(e) => {
                          currentInternData.program = e.target.value;
                          setFormValueAndUpdateBar("program", e.target.value);
                        }}
                      >
                        <option disabled selected hidden>
                          {currentInternData.program || ""}
                        </option>
                        <option>Embajador Tec</option>
                        <option>Embajador Tecmilenio</option>
                        <option>Experiencias Tec</option>
                        <option>Experiencias Tecmilenio</option>
                        <option>Novus</option>
                        <option>Practicante Externo</option>
                        <option>Storyteller</option>
                        <option>Teaching Assistant</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                </Col>
              </Row>

              <Row>
                <Col>
                  <Button
                    className="float-left ml-2"
                    variant="success"
                    type="button"
                    onClick={() => sendEmail()}
                  >
                    Enviar formulario al practicante
                  </Button>
                  <Button
                    className="float-right ml-2"
                    variant="success"
                    type="button"
                    onClick={() => sendDocs(currentInternContract)}
                  >
                    Enviar documentos a firmar
                  </Button>
                </Col>
              </Row>
              <br></br>
              <h6>
                <strong>- Documentación del practicante</strong>
              </h6>
              <InternDocumentsTable
                internDocs={internDocs}
                currentInternData={currentInternData}
                currentInternId={currentInternId}
                setUpdateInfo={setUpdateInfo}
                updateInfo={updateInfo}
                canEditIntern={canEditIntern}
              />
              <Form.Label>
                <h6>
                  <strong>- Histórico de convenios</strong>
                </h6>
              </Form.Label>
              {renderInternContracts()}

              <Row>
                <Col className="mt-4">
                  <Button
                    className="float-right ml-2"
                    variant="primary"
                    type="submit"
                  >
                    Guardar Cambios
                  </Button>

                  {currentInternData.internStatus != "Inactivo" &&
                    requireSife && (
                      <Button
                        className="float-right ml-2"
                        variant="success"
                        type="button"
                        onClick={() => sendDocs(currentInternContract)}
                      >
                        Enviar documentos a firmar
                      </Button>
                    )}

                  {currentInternData && verifyContractStatus() && (
                    <Button
                      className="float-left ml-2"
                      variant="success"
                      type="button"
                      onClick={() => setShowRenewIntern(true)}
                    >
                      Renovar
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Row>

          {isLoading && <LoadingModal isLoading={isLoading} />}
        </motion.div>
      )}

      {documentationTab == "internMedicalInsurance" && (
        <InternMedicalInsurance
          currentInternId={currentInternId}
          currentInternData={currentInternData}
          setCurrentTab={setCurrentTab}
          updatedFromPrefacture={updatedFromPrefacture}
          setUpdateInfo={setUpdateInfo}
          updateInfo={updateInfo}
          setFormChanged={setFormChanged}
          isLoadingInternData={isLoading}
          internContracts={internContracts}
          currentInternContract={currentInternContract}
        />
      )}

      <NewDepartmentModal
        showNewDepartmentModal={showNewDepartmentModal}
        setShowNewDepartmentModal={setShowNewDepartmentModal}
        setUpdateInfo={setUpdateInfo}
        updateInfo={updateInfo}
        currentCompanyId={currentCompanyId}
      />

      <NewCostCenterModal
        showNewCostCenterModal={showNewCostCenterModal}
        setShowNewCostCenterModal={setShowNewCostCenterModal}
        setUpdateInfo={setUpdateInfo}
        currentDepartmentId={currentDepartmentId}
        updateInfo={updateInfo}
        currentCompanyId={currentCompanyId}
        isITESMCompany={isITESMCompany}
      />

      <CreateLeaderUserModal
        showCreateLeaderModal={showCreateLeaderModal}
        setShowCreateUserModal={setShowCreateUserModal}
        currentCompanyId={currentCompanyId}
        regexEmail={regexEmail}
        currentDepartmentId={currentDepartmentId}
        setUpdateInfo={setUpdateInfo}
        updateInfo={updateInfo}
      />

      <VerifyChangedOnDate
        setShowVerifyChangeOnDate={setShowVerifyChangeOnDate}
        showVerifyChangeOnDate={showVerifyChangeOnDate}
        setDateChanged={setDateChanged}
        setDateConfirm={setDateConfirm}
        dateConfirm={dateConfirm}
        setConfirmation={setConfirmation}
      />
      <VerifyChangeTab
        setShowVerifyChangeTab={setShowVerifyChangeTab}
        showVerifyChangeTab={showVerifyChangeTab}
        setDocumentationTab={setDocumentationTab}
        newTabName={newTabName}
        setFormChanged={setFormChanged}
      />
      <NewContractTab
        setShowCreateNewContract={setShowCreateNewContract}
        showCreateNewContract={showCreateNewContract}
        setCreateNewContract={setCreateNewContract}
        setIsNewContract={setIsNewContract}
      />
      <RenewIntern
        internData={currentInternData}
        setShowRenewIntern={setShowRenewIntern}
        showRenewIntern={showRenewIntern}
        contractStartDate={contractStartDate}
        contractEndDate={contractEndDate}
        setDuration={setDuration}
        duration={duration}
        renewContractStartDate={renewContractStartDate}
        renewContractEndDate={renewContractEndDate}
        setRenewContractEndDate={setRenewContractEndDate}
        setRenewContractStartDate={setRenewContractStartDate}
        currentInternId={currentInternId}
        setUpdateInfo={setUpdateInfo}
        updateInfo={updateInfo}
        requireInsurance={requireInsurance}
        renewContract={renewContract}
        currentInternContract={currentInternContract}
        isNewCurrentContractIntern={isNewCurrentContractIntern}
        newInternContractData={newInternContractData}
        dataToUpdateByIntern={dataToUpdateByIntern}
        setIsNewCurrentContractIntern={setIsNewCurrentContractIntern}
        isITESMCompany={isITESMCompany}
        isCaintraUser={isCaintraUser}
        internDataAux={internDataAux}
        setInternDataAux={setInternDataAux}
        requireSife={requireSife}
      />

      <UpdateContractStatus
        showUpdateContrat={showUpdateContrat}
        setShowUpdateContract={setShowUpdateContract}
        currentInternContract={currentInternContract}
        contractToUpdate={contractToUpdate}
        contractActionLabel={contractActionLabel}
        context={context}
        setUpdateInfo={setUpdateInfo}
        updateInfo={updateInfo}
        currentInternData={currentInternData}
        currentInsuranceData={currentInsuranceData}
        isRenovationCase={isRenovationCase}
        internContracts={internContracts}
        internPolicies={internPolicies}
        currentCompany={currentCompany}
        sendDocs={sendDocs}
        requireSife={requireSife}
        requireInsurance={requireInsurance}
        isITESMCompany={isITESMCompany}
      />

      <ApproveConvenio
        showApproveConvenio={showApproveConvenio}
        setShowApproveConvenio={setShowApproveConvenio}
        approveConvenioHandler={approveConvenioHandler}
        setCurrentTab={setCurrentTab}
        setDocumentationTab={setDocumentationTab}
      />
    </motion.div>
  );
}
